import React, { useState } from 'react';
import './CampoTexto.css';
import iconVerSenhaOn from './iconOlhoSenhaOn.svg';
import iconVerSenhaOff from './iconOlhoSenhaOff.svg';

const CampoTexto = (props) => {
    const { label, type, name, onChange, borderColor} = props;
    const [verSenha, setVerSenha] = useState(false);

    const handleChange = (event) => {
        if (onChange) {
            onChange({ name, value: event.target.value });
        }
    };

    const togglePasswordVisibility = () => {
        setVerSenha(!verSenha);
    };

    return (
        <div className="campo-texto">
            <label htmlFor={name}>{label}</label>
            <div style={{borderColor: !borderColor ? '#000000' : 'red'}}>
                <input
                    type={verSenha && type === 'password' ? 'text' : type}
                    id={name}
                    name={name}
                    onChange={handleChange}
                />
                {type === 'password' && (
                    <img
                        src={verSenha ? iconVerSenhaOff : iconVerSenhaOn}
                        alt={verSenha ? 'Esconder senha' : 'Ver senha'}
                        onClick={togglePasswordVisibility}
                        className="toggle-password-visibility"
                        title={verSenha ? 'Esconder senha' : 'Ver senha'}
                    />
                )}
            </div>
        </div>
    );
}

export default CampoTexto;
