import React from 'react';
import './Checkbox.css'

const Checkbox = ({ isChecked, onChange, circular, laudo }) => {
  if(circular === null){
    circular = false;
  }
  return (
    <div style={{height: 'fit-content', width: 'fit-content'}}>
      <label className={`chk ${circular ? 'circular' : ''} ${laudo === 'aprovado' ? 'laudoAprovado' : ''} ${laudo === 'reprovado' ? 'laudoReprovado' : ''}`}>
        <input
          type='checkbox'
          style={{ display: 'none' }}
          checked={isChecked}
          onChange={onChange}
        />
        {isChecked ? <span>✔</span> : <span></span>}
      </label>
    </div>
  );
};

export default Checkbox;
