import AppRoutes from './routes';
import './Fonts.css';
import { PrimeReactProvider } from 'primereact/api';
function App() {
  return (
    <PrimeReactProvider>
      <AppRoutes/>
    </PrimeReactProvider>
  );
}

export default App;