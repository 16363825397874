import React from 'react';
import './Paginate.css'
import iconEsquerda from './iconPaginacaoEsquerda.svg';
import iconDireita from './iconPaginacaoDireita.svg';

const Paginate = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers = [];

  if (totalPages <= 3) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    if (currentPage === 1) {
      pageNumbers.push(currentPage, currentPage + 1, currentPage + 2);
    } else if (currentPage === totalPages) {
      pageNumbers.push(currentPage - 2, currentPage - 1, currentPage);
    } else {
      pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
    }
  }

  return (
    <nav>
      <ul className='sistemaDePaginacao'>
        <li>
          <button className={currentPage === 1 ? 'classeBotaoPaginacaoDesabilitado itemDoSistemaDePaginacao' : 'itemDoSistemaDePaginacao'} onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            <img src={iconEsquerda} alt=''></img>
          </button>
        </li>
        {pageNumbers.map(number => (
          <li key={number}>
            <button onClick={() => paginate(number)} className={currentPage === number ? 'itemSelecionadoDoSistemaDePaginacao' : 'itemDoSistemaDePaginacao'}>
              {number}
            </button>
          </li>
        ))}
        <li>
          <button className={currentPage === totalPages ? 'classeBotaoPaginacaoDesabilitado itemDoSistemaDePaginacao' : 'itemDoSistemaDePaginacao'}  onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
            <img src={iconDireita} alt=''></img>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Paginate;
