
import Header from '../../componentes/Header';
import React, { useEffect, useState } from 'react';
import NotaFiscal from '../../componentes/NotaFiscal';
import iconFile from './imgs/iconFile.svg';
import ListagemDeChats from '../../componentes/ListagemDeChats';
import iconMessage1 from './imgs/icon1Mensagem.svg';
import iconMessage2 from './imgs/icon2Mensagem.svg';
import iconUser from './imgs/iconUser.svg';
import { Link } from 'react-router-dom';
import "./Historico.css";
import Cookies from 'js-cookie';
import iconPesquisa from './imgs/iconPesquisa.svg';
import { useNavigate } from 'react-router-dom';
function Historico() {
  const [listaDeNotasFiscais, setListaDeNotasFiscais] = useState([]);
  const [nomeUsuario, setNomeUsuario] = useState('');
  const [chatVisible, setChatVisible] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = new Headers({
            'Authorization': `Bearer ${token}`,
          });

          const response = await fetch(`${process.env.REACT_APP_API_URL}/protocolo/resgatarNomeUsuario`, { headers });
          const data = await response.json();
          setNomeUsuario(data.nome);
        }
      } catch (error) {
        console.error('Erro ao obter nome de usuário:', error);
      }
    };

    fetchData();
  }, []);
  const pesquisarNotasFiscais = async () => {
    try {
      const token = Cookies.get('token');

      if (token) {
        const headers = new Headers({
          'Authorization': `Bearer ${token}`,
        });

        const response = await fetch(`${process.env.REACT_APP_API_URL}/notas-fiscais`, { headers });

        if (response.ok) {
          const data = await response.json();
          setListaDeNotasFiscais(data);
        } else {
          console.error('Erro na requisição:', response.statusText);
        }
      } else {
        console.error('Token não encontrado no localStorage.');
      }
    } catch (error) {
      console.error('Erro ao obter notas fiscais:', error);
    }
  }

  const AbrirChat = () => {
    setChatVisible(true);
    document.body.classList.add('no-overflow');
    document.documentElement.classList.add('no-overflow');
    document.querySelector('[name="divInicialHistoricoDeCompras"]').classList.add('no-overflow');
  };

  const FecharChat = () => {
    setChatVisible(false);
    document.body.classList.remove('no-overflow');
    document.documentElement.classList.remove('no-overflow');
    document.querySelector('[name="divInicialHistoricoDeCompras"]').classList.remove('no-overflow');
  };
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });
  const navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });
        } catch {
          setStatusUsuario({ logado: false, admin: false });
          navigate('/login');
        }
      };
      fetchData();
    } else {
      navigate('/login');
    }
  }, [navigate]);
  return (
    <div name="divInicialHistoricoDeCompras" className="HistoricoDeCompras">
      <div className="content">
        <Header logado={statusUsuario.logado} admin={statusUsuario.admin} />
        <div className='conteudoDaPagina'>
          <div className="headerHistorico">
            <div className='nameHistorico'>
              {nomeUsuario && <h2 className='usernameHistorico'>{nomeUsuario}</h2>}
            </div>
            <div className='inferiorHeaderHistorico'>
              <div className="infoHistorico">
                <Link to={"/DadosCadastrais"} style={{ display: 'contents' }}>
                  <div className='itemHeaderHistorico'>
                    <img src={iconUser} alt='Icone Usuario'></img>
                    <h2>Dados cadastrais</h2>
                  </div>
                </Link>
                <Link to={"/ListaDeProtocolos"} style={{ display: 'contents' }}>
                  <div className='itemHeaderHistorico'>
                    <img src={iconFile} alt='Icone Arquivo'></img>
                    <h2>Meus protocolos</h2>
                  </div>
                </Link>
                <Link to={"/abrirNovoProtocolo"} style={{ display: 'contents' }}>
                  <div className='itemHeaderHistorico'>
                    <h1 style={{margin: 0, color: '#003594'}}>+</h1>
                    <h2>Abrir novo Protocolo</h2>
                  </div>
                </Link>
              </div>
              <div onClick={AbrirChat} className="divDoBotaoChat">
                  <img src={iconMessage1} alt=''></img>
                  <h2>Abrir mensagens</h2>
                  <img src={iconMessage2} alt=''></img>
              </div>
            </div>
          </div>
          <div className='divMaiorListagemDeChatsHistorico' style={{ display: chatVisible ? 'flex' : 'none' }}>
            <div style={{ position: 'absolute', height: '100%', width: '100%', top: '120px', left: '0', backgroundColor: 'rgba(128, 128, 128, 0.363)' }} onClick={FecharChat}></div>
            <ListagemDeChats onClose={FecharChat} />
          </div>
          <h1 className='tituloHistoricoDeCompras'>Histórico de Compras</h1>
          <div className='divDoPesquisarNotaFiscal'>
            <div>
            <img src={iconPesquisa} alt=''></img>
            <input placeholder='Buscar pelo nº da Nota'></input>
            </div>
            <button onClick={pesquisarNotasFiscais}>Buscar</button>
          </div>
          <hr></hr>
          <div>
            <div className='cabecalhoListaNotasFiscais'>
              <div className='tituloHistoricoNumNota'>Nº da NF ou Cupom</div>
              <div className='tituloHistoricoData'>Data</div>
              <div className='tituloHistoricoValorTotal'>Valor Total</div>
            </div>
          </div>
          <hr></hr>
          <ul style={{ padding: "0" }}>
            {listaDeNotasFiscais.map((nota) => (
              <NotaFiscal key={nota.numeroNotaFiscal} nota={nota} selecionar={false} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Historico;