
import Header from '../../componentes/Header';
import { useLocation } from 'react-router-dom';
import DevolucaoIG from '../../componentes/Devolucao/DevolucaoIG';
function MotivoProtocoloDevolucaoPage() {
  const location = useLocation();
  const itensSelecionados = location && location.state ? location.state.itensSelecionados : [];
  const notaFiscal = location && location.state ? location.state.notaFiscal : [];
  return (
    <div className="contenteudo">
      <Header logado={true} />
      <DevolucaoIG itensSelecionados={itensSelecionados} notaFiscal={notaFiscal}/>
    </div>
  );
}

export default MotivoProtocoloDevolucaoPage;
