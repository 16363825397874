import React from 'react';
import './NotaFiscal.css';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';

const formatarValor = (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
const NotaFiscal = ({ nota, selecionar }) => {
    const { pathname } = useLocation();
    let buttonDetalharStyle = { display: 'flex' };
    let buttonSelecionarStyle = { display: 'none' };
    let marginValorTotal = {marginRight: '19%'};

    if (selecionar) {
        buttonDetalharStyle = { display: 'none' };
        buttonSelecionarStyle = { display: 'block' };
        marginValorTotal = {marginRight: '26%'};
    }
    const dataFormatada = format(new Date(nota.data.replaceAll('-', '/')), 'dd-MM-yyyy');
    const valorTotalFormatado = formatarValor(nota.valorTotal);
    return (
        <li className="nota-fiscal">
            <div className='itemNota' style={{ ...buttonSelecionarStyle}}>Pedido nº {nota.id}</div>
            <div className='itemNota numeroNotaFiscalMobile' >NF: {nota.numeroNotaFiscal}</div>
            <div className='itemNota dataNotaFiscalMobile' >{dataFormatada.replaceAll('-', '/')}</div>
            <div className='itemNota textoValorTotalNotaFiscal' style={{...marginValorTotal, marginLeft: '1%' }}>{valorTotalFormatado}</div>
            <Link
                to={`/detalharNotaFiscal/${nota.numeroNotaFiscal}`}
                className="button-detalhar"
                style={{ textAlign: 'right', width: '7lh', marginRight: '0', paddingRight: '0', ...buttonDetalharStyle }}
            >
                Ver mais detalhes
            </Link>
            <Link
                to={`${pathname}/selecionarNotaFiscal/${nota.numeroNotaFiscal}`}
                className="button-selecionar"
                style={{ ...buttonSelecionarStyle }}
            >
                Selecionar
            </Link>
        </li>
    );
};

export default NotaFiscal;
