import React, { useEffect, useState } from 'react';
import './ProtocoloMobile.css';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const ProtocoloMobile = ({ protocolo, selecionar }) => {
    let buttonDetalharStyle = { display: 'flex' };
    const [textoStatus, setTextoStatus] = useState("");
    const [textoTipo, setTextoTipo] = useState("");

    if (selecionar) {
        buttonDetalharStyle = { display: 'none' };
    }
    const dataFormatada = format(new Date(protocolo.dataCriacao.replaceAll('-', '/')), 'dd/MM/yyyy').toString();
    useEffect(() => {
        if (protocolo.status === "PROTOCOLO_EM_ANALISE") {
            setTextoStatus(<span className={"textoStatus classeStatusProtocoloEmAnalise"}>Protocolo em análise</span>)
        } else if (protocolo.status === "ENVIO_AUTORIZADO") {
            setTextoStatus(<span className={"textoStatus classeStatusProtocoloEnvioAutorizado"}>Envio autorizado</span>)
        } else if (protocolo.status === "ITEM_EM_ANALISE") {
            setTextoStatus(<span className={"textoStatus classeStatusProtocoloItemEmAnalise"}>Item em análise</span>)
        } else if (protocolo.status === "LAUDO_APROVADO") {
            setTextoStatus(<span className={"textoStatus classeStatusProtocoloAprovado"}>Laudo aprovado</span>)
        } else if (protocolo.status === "LAUDO_REPROVADO") {
            setTextoStatus(<span className={"textoStatus classeStatusProtocoloReprovado"}>Laudo reprovado</span>)
        } else if (protocolo.status === "ENCERRADO") {
            setTextoStatus(<span className={"textoStatus classeStatusProtocoloEncerrado"}>Encerrado</span>)
        }
    }, [protocolo.status])
    useEffect(() => {
        if (protocolo.tipo === "GARANTIA") {
            setTextoTipo("Garantia")
        } else if (protocolo.tipo === "GARANTIA_EXPRESSA") {
            setTextoTipo("Garantia expressa")
        } else if (protocolo.tipo === "DEVOLUCAO") {
            setTextoTipo("Devolução")
        }
    }, [protocolo.tipo])
    return (
        <Link to={`/detalharProtocolo/${protocolo.id}`} style={{width: '100%', height: 'fit-content'}}>
            <div className="protocoloDaListaMobile">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label className='textoTipoDeProtocoloMobile' >{textoTipo}</label>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className='subtituloProtocoloMobile'>N° do protocolo</label>
                        <label className='itemNota'>{protocolo.id}</label>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className='subtituloProtocoloMobile'>Data da criação</label>
                        <label className='itemNota' >{dataFormatada}</label>
                    </div>
                </div>
                <label className="itemNota">{textoStatus}</label>
            </div>
        </Link>
    );
};

export default ProtocoloMobile;
