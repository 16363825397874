
import Header from '../../componentes/Header';
import SelecionarItensNotaFiscal from "../../componentes/SelecionarItensNotaFiscal"
import "./SelecionarItensNotaFiscalPage.css"
function SelecionarItensNotaFiscalPage() {
  return (
      <div className="contenteudo">
        <Header logado={true}/>
        <SelecionarItensNotaFiscal />
    </div>
  );
}
export default SelecionarItensNotaFiscalPage;