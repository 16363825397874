import Header from '../../../componentes/Header';
import React, { useState, useEffect } from 'react';
import './BannersPopupsAdmin.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import BotaoDeVoltarAdmin from '../../../componentes/BotaoDeVoltarAdmin';
import iconEnviarArquivo from './imgs/iconEnviarArquivoAdmin.svg';
import iconDeletarBanner from './imgs/iconDeletarBanner.svg';
import { OrderList } from 'primereact/orderlist';
import iconPassarBanner from './imgs/iconPassarBanner.svg';
function BannersPopupsAdmin() {
  const [idBannerDeletar, setIdBannerDeletar] = useState(null);

  const [mostrarAdicionarBanner, setMostrarAdicionarBanner] = useState(false);

  const [bannerParaEnviar, setBannerParaEnviar] = useState({
    textoAlt: "",
    link: "",
    imagem: "",
    imagemMobile: "",
  });

  const [banners, setBanners] = useState([]);
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });

  const [mostrarPopupDeletarBanner, setMostrarPopupDeletarBanner] = useState(false);
  const [mostrarPopupReordenarBanners, setMostrarPopupReordenarBanners] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });

          if (!data) navigate('/login');
        } catch {
          setStatusUsuario({ logado: false, admin: false });
          navigate('/login');
        }
      };
      fetchData();
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    listarBanners();
    listarPopup();
  }, []);


  // Função para transformar byte em imagem
  function transformarByteEmImagem(itemData) {
    const byteCharacters = atob(itemData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    return url;
  }

  //BANNERS ------------


  // Atualizar o estado da imagem do banner desktop
  async function handleEditarImagemBannerChange(e, id) {
    let imagem = e.target.files[0];

    const updatedBanners = banners.map(b =>
      b.id === id ? { ...b, imagem: imagem } : b
    );

    setBanners(updatedBanners);
  }
  // Atualizar o estado da imagem do banner mobile
  async function handleEditarImagemBannerMobileChange(e, id) {
    let imagem = e.target.files[0];

    const updatedBanners = banners.map(b =>
      b.id === id ? { ...b, imagemMobile: imagem } : b
    );

    setBanners(updatedBanners);
  }

  // Atualizar o estado da imagem do banner para enviar
  async function handleEditarImagemBannerParaEnviarChange(e) {
    let imagem = e.target.files[0];

    setBannerParaEnviar({ ...bannerParaEnviar, imagem: imagem });
  }
  // Atualizar o estado da imagem do banner mobile para enviar
  async function handleEditarImagemMobileBannerParaEnviarChange(e) {
    let imagem = e.target.files[0];

    setBannerParaEnviar({ ...bannerParaEnviar, imagemMobile: imagem });
  }


  // Função para salvar alterações no banner
  async function salvarBanner(bannerId) {
    try {
      const formData = new FormData();

      const bannerAtualizado = banners.find(b => b.id === bannerId);

      if (bannerAtualizado.imagem instanceof File) {
        formData.append('banner', bannerAtualizado.imagem);
      }

      if (bannerAtualizado.imagemMobile instanceof File) {
        formData.append('bannerMobile', bannerAtualizado.imagemMobile);
      }

      formData.append('altDoBanner', bannerAtualizado.textoAlt);
      formData.append('linkDoBanner', bannerAtualizado.link);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/banner/${bannerId}`, {
        method: 'PUT',
        body: formData,
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`
        }
      });

      if (!response.ok) {
        throw new Error(`Erro ao salvar o banner: ${response.status}`);
      }

      listarBanners();

    } catch (error) {
      console.error("Erro ao salvar banner", error);
    }
  }


  // Função para deletar o banner
  async function deletarBanner() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/banner/removerBanner/${idBannerDeletar}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`
        }
      });

      if (!response.ok) {
        throw new Error(`Erro ao deletar o banner: ${response.status}`);
      }

      listarBanners();
      setMostrarPopupDeletarBanner(false);
    } catch (error) {
      console.error("Erro ao deletar banner", error);
    }
  }

  async function listarBanners() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/banner/listarBanners`);

      if (!response.ok) {
        throw new Error(`Erro na resposta do servidor: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();

      setBanners(data);
    } catch (error) {
      console.log("Erro ao requisitar banners", error);
    }
  };
  async function adicionarNovoBanner() {
    try {
      const formData = new FormData();

      formData.append('banner', bannerParaEnviar.imagem);
      formData.append('bannerMobile', bannerParaEnviar.imagemMobile);
      formData.append('altDoBanner', bannerParaEnviar.textoAlt);
      formData.append('linkDoBanner', bannerParaEnviar.link);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/banner/enviarNovosBanners`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Erro ao enviar o banner: ${response.status}`);
      }

      listarBanners();
      setMostrarAdicionarBanner(false);
      setBannerParaEnviar({
        textoAlt: '',
        link: '',
        imagem: '',
        imagemMobile: '',
      })

    } catch (error) {
      console.error("Erro ao enviar novo banner", error);
    }
  }
  const bannerTemplate = (item) => {
    return (
      <div style={{ display: 'contents' }}>
        <img style={{ pointerEvents: 'none', height: '5em', width: 'fit-content' }} src={transformarByteEmImagem(item.imagem)} alt={item} width="100" />
      </div>
    );
  };
  const reordenarBanners = async () => {
    const token = Cookies.get('token');
    const novaOrdemBanners = banners.map((banner, index) => ({
      id: banner.id,
      novoIndice: index
    }));
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/banner/reordenarBanners`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(novaOrdemBanners),
      });

      if (response.ok) {
        window.location.reload();
      } else if (response.status === 403) {
        console.error('Você não tem permissão para reordenar os banners.');
      } else {
        console.error('Erro na requisição de reordenar banners:', response.statusText);
      }
    } catch (error) {
      console.error('Erro na comunicação com o servidor:', error);
    }
  };
  async function resetarOrdemBanners() {
    banners.sort((a, b) => a.indice - b.indice);
  }

  // POPUPS ------------
  const [isPopupNovo, setIsPopupNovo] = useState(true);
  const [popup, setPopup] = useState({ id: "",
    textoAlt: "",
    dataExpiracao: "",
    link: "",
  });
  const [mostrarPopupDeletarPopup, setMostrarPopupDeletarPopup] = useState(false);

    async function listarPopup() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/popups/listarPopups`);

        if (!response.ok) {
          throw new Error(`Erro na resposta do servidor: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        setPopup(data);
        if(data.id){
          setIsPopupNovo(false);
        }

      } catch (error) {
        console.log("Erro ao requisitar banners", error);
      }
    };

    // Função para deletar o popup
    async function deletarPopup() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/popups/removerPopup/${popup.id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`
          }
        });
  
        if (!response.ok) {
          throw new Error(`Erro ao deletar o banner: ${response.status}`);
        }
        setPopup({
          id: "",
          textoAlt: "",
          dataExpiracao: "",
          link: "",
        })
        setIsPopupNovo(true);
        listarPopup();
        setMostrarPopupDeletarPopup(false);
      } catch (error) {
        console.error("Erro ao deletar banner", error);
      }
    }
    async function adicionarNovoPopup() {
      try {
        const formData = new FormData();
  
        formData.append('popup', popup.imagem);
        formData.append('altDoPopup', popup.textoAlt || "");
        formData.append('dataExpiracaoPopup', popup.dataExpiracao);
        formData.append('linkDoPopup', popup.link || "");
  
        const response = await fetch(`${process.env.REACT_APP_API_URL}/popups/enviarNovosPopups`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        });
  
        if (!response.ok) {
          throw new Error(`Erro ao enviar o banner: ${response.status}`);
        }
  
        listarPopup();
  
      } catch (error) {
        console.error("Erro ao enviar novo banner", error);
      }
    }
    async function salvarPopup() {
      try {
        const formData = new FormData();
  
        formData.append('popup', popup.imagem);
        formData.append('altDoPopup', popup.textoAlt);
        formData.append('dataExpiracaoPopup', popup.dataExpiracao);
        formData.append('linkDoPopup', popup.link);
  
        const response = await fetch(`${process.env.REACT_APP_API_URL}/popups/atualizarPopup/${popup.id}`, {
          method: 'PUT',
          body: formData,
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        });
  
        if (!response.ok) {
          throw new Error(`Erro ao enviar o banner: ${response.status}`);
        }
  
        listarPopup();
  
      } catch (error) {
        console.error("Erro ao enviar novo banner", error);
      }
    }
    async function handleEditarImagemPopup(e) {
      let imagem = e.target.files[0];
  
      setPopup({ ...popup, imagem: imagem });
    }
  return (
    <div className="homeAdmin-wrapper">
      <Header logado={statusUsuario.logado} admin={statusUsuario.admin} />

      {mostrarPopupReordenarBanners && (
        <div className='marcasAdmin-popupCadastrarNovaMarca' style={{ maxWidth: '40em', zIndex: '10' }}>
          <h2>Reordenar Banners</h2>
          <p style={{ marginBottom: '4em' }}>Clique no banner que deseja mover de posição, após isso utilize os botões para move-lo para trás ou para frente da fila.</p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <OrderList moveUpIcon={<label><img style={{ cursor: 'pointer', transform: 'scaleX(-1)' }} src={iconPassarBanner} alt='Mover banner para frente'></img></label>} moveDownIcon={<label><img style={{ cursor: 'pointer' }} src={iconPassarBanner} alt='Mover banner para frente'></img></label>} moveTopIcon={<div style={{ display: 'none' }}></div>} value={banners} onChange={(e) => setBanners(e.value)} itemTemplate={bannerTemplate} dragdrop className='classeOrdenadorDeBanners'></OrderList>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '4em' }}>
            <button style={{ padding: '1em 2em' }} className='admin-botaoAzulComHover' onClick={() => { setMostrarPopupReordenarBanners(false); resetarOrdemBanners() }}>Fechar Popup</button>
            <button style={{ padding: '1em 2em' }} className='admin-botaoBrancoComHover' onClick={reordenarBanners}>Reordenar Banners</button>
          </div>
        </div>
      )}

      {mostrarPopupDeletarBanner && (
        <div className='marcasAdmin-popupCadastrarNovaMarca' style={{ maxWidth: '28em', zIndex: '10' }}>
          <h2>Excluir Banner</h2>
          <p style={{ marginBottom: '4em' }}>Você tem certeza que deseja excluir este Banner? Esta ação não pode ser desfeita.</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button style={{ padding: '1em 2em' }} className='admin-botaoAzulComHover' onClick={() => { setMostrarPopupDeletarBanner(false) }}>Não, cancelar</button>
            <button style={{ padding: '1em 2em' }} className='admin-botaoBrancoComHover' onClick={deletarBanner}>Sim, excluir</button>
          </div>
        </div>
      )}

      {mostrarPopupDeletarPopup && (
        <div className='marcasAdmin-popupCadastrarNovaMarca' style={{ maxWidth: '28em', zIndex: '10' }}>
          <h2>Excluir Popup</h2>
          <p style={{ marginBottom: '4em' }}>Você tem certeza que deseja excluir este Popup? Esta ação não pode ser desfeita.</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button style={{ padding: '1em 2em' }} className='admin-botaoAzulComHover' onClick={() => { setMostrarPopupDeletarPopup(false); setMostrarPopupDeletarBanner(false); setMostrarPopupReordenarBanners(false) }}>Não, cancelar</button>
            <button style={{ padding: '1em 2em' }} className='admin-botaoBrancoComHover' onClick={deletarPopup}>Sim, excluir</button>
          </div>
        </div>
      )}

      <div className="homeAdmin-content">
        <BotaoDeVoltarAdmin />
        <div className="marcasAdmin-titleDiv">
          <h1 className="homeAdmin-title">Banners e Pop-ups</h1>
        </div>
        <div className='bannersPopups-divMaiorFundoBranco'>
          <div className='bannersPopups-divSubtitulo'>
            <h2>Banners da Página Inicial (Carrossel)</h2>
            <button className='admin-botaoBrancoComHover' onClick={() => { setMostrarPopupDeletarPopup(false); setMostrarPopupDeletarBanner(false); setMostrarPopupReordenarBanners(true) }}>Reordenar Banners</button>
          </div>
          <div style={{ padding: '0 4% 0% 4%' }}>
            {banners.map((banner, index) => (
              <div key={banner.id}>
                <div className="marcasAdmin-inputsDiv" style={{ alignItems: 'center', borderBottom: '1px solid #CFCFCF' }}>
                  <div className='bannersPopups-divTituloBannerNumerado'>
                    <h1 className='bannersPopups-tituloBannerNumerado'>Banner {index + 1}</h1>
                    <button onClick={() => { setMostrarPopupDeletarPopup(false); setMostrarPopupDeletarBanner(true); setIdBannerDeletar(banner.id); setMostrarPopupReordenarBanners(false) }}><img src={iconDeletarBanner} alt='Deletar banner'></img></button>
                  </div>
                  <div className='produtosAdmin-divMaiorInputs' style={{ justifyContent: 'space-between', padding: '2% 10% 2% 0' }}>
                    <div className='produtosAdmin-divDosInputsDeTexto' style={{ maxWidth: '30%' }}>
                      <label>Texto alternativo do banner (acessibilidade)</label>
                      <input
                        type="text"
                        value={banner.textoAlt}
                        onChange={(e) => {
                          const updatedBanners = banners.map(b =>
                            b.id === banner.id ? { ...b, textoAlt: e.target.value } : b
                          );
                          setBanners(updatedBanners);
                        }}
                      />

                      <label>Link que o banner irá redirecionar ao clicado:</label>
                      <input
                        type="text"
                        value={banner.link}
                        onChange={(e) => {
                          const updatedBanners = banners.map(b =>
                            b.id === banner.id ? { ...b, link: e.target.value } : b
                          );
                          setBanners(updatedBanners);
                        }}
                      />

                      <button className='admin-botaoAzulComHover' onClick={() => salvarBanner(banner.id)}>Salvar Banner</button>
                    </div>
                    <div style={{ display: 'flex', gap: '1em', width: '60%' }}>
                      <div className='produtosAdmin-divDoInputDeImagem' style={{ width: '50%', minWidth: '50%' }}>
                        <label>Versão Desktop</label>

                        {(!banner.imagem) && (
                          <div className="marcasAdmin-upload-area" style={{ height: '-webkit-fill-available' }}>
                            <label htmlFor={"logotipoUpload " + banner.id} className="marcasAdmin-upload-label" style={{ padding: '4em 7em' }}>
                              <img src={iconEnviarArquivo} alt='' />
                              <span>Escolher arquivo</span>
                              <input id={"logotipoUpload " + banner.id} type="file" onChange={(e) => handleEditarImagemBannerChange(e, banner.id)} className="input-file" />
                            </label>
                          </div>
                        )}

                        {banner.imagem && (
                          <div className="marcasAdmin-upload-area" style={{ border: '1px solid transparent', height: '-webkit-fill-available' }}>
                            <label htmlFor={"logotipoUpload " + banner.id} className="marcasAdmin-upload-label" style={{ padding: '0', overflow: 'hidden' }}>
                              <input id={"logotipoUpload " + banner.id} type="file" onChange={(e) => handleEditarImagemBannerChange(e, banner.id)} className="input-file" style={{ display: 'none' }} />
                              {banner.imagem && (
                                <img
                                  src={
                                    typeof banner.imagem === 'string'
                                      ? transformarByteEmImagem(banner.imagem)
                                      : URL.createObjectURL(banner.imagem)
                                  }
                                  style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '13em', objectFit: 'contain' }}
                                  alt=""
                                />
                              )}
                            </label>
                          </div>
                        )}

                        <p className='marcasAdmin-auxilioMedidas'>Medidas 320px por 200px</p>
                      </div>

                      <div className='produtosAdmin-divDoInputDeImagem' style={{ width: '30%', minWidth: '40%' }}>
                        <label>Versão Mobile</label>

                        {(!banner.imagemMobile) && (
                          <div className="marcasAdmin-upload-area" style={{ height: '-webkit-fill-available' }}>
                            <label htmlFor={"logotipoUploadMobile " + banner.id} className="marcasAdmin-upload-label" style={{ padding: '4em 7em' }}>
                              <img src={iconEnviarArquivo} alt='' />
                              <span>Escolher arquivo</span>
                              <input id={"logotipoUploadMobile " + banner.id} type="file" onChange={(e) => handleEditarImagemBannerMobileChange(e, banner.id)} className="input-file" />
                            </label>
                          </div>
                        )}

                        {banner.imagemMobile && (
                          <div className="marcasAdmin-upload-area" style={{ border: '1px solid transparent', height: '-webkit-fill-available' }}>
                            <label htmlFor={"logotipoUploadMobile " + banner.id} className="marcasAdmin-upload-label" style={{ padding: '0', overflow: 'hidden' }}>
                              <input id={"logotipoUploadMobile " + banner.id} type="file" onChange={(e) => handleEditarImagemBannerMobileChange(e, banner.id)} className="input-file" style={{ display: 'none' }} />
                              {banner.imagemMobile && (
                                <img
                                  src={
                                    typeof banner.imagemMobile === 'string'
                                      ? transformarByteEmImagem(banner.imagemMobile)
                                      : URL.createObjectURL(banner.imagemMobile)
                                  }
                                  style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '13em', objectFit: 'contain' }}
                                  alt=""
                                />
                              )}
                            </label>
                          </div>
                        )}

                        <p className='marcasAdmin-auxilioMedidas'>Medidas 320px por 200px</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}


            {mostrarAdicionarBanner &&
              <div>
                <div className="marcasAdmin-inputsDiv" style={{ alignItems: 'center', borderBottom: '1px solid #CFCFCF' }}>
                  <div className='bannersPopups-divTituloBannerNumerado'>
                    <h1 className='bannersPopups-tituloBannerNumerado'>Novo banner</h1>
                    <button onClick={() => {
                      setMostrarAdicionarBanner(false); setBannerParaEnviar({
                        textoAlt: '',
                        link: '',
                        imagem: '',
                        imagemMobile: '',
                      })
                    }}><img src={iconDeletarBanner} alt='Deletar banner'></img></button>
                  </div>
                  <div className='produtosAdmin-divMaiorInputs' style={{ justifyContent: 'space-between', padding: '2% 10% 2% 0' }}>
                    <div className='produtosAdmin-divDosInputsDeTexto' style={{ maxWidth: '30%' }}>
                      <label>Texto alternativo do banner (acessibilidade)</label>
                      <input
                        type="text"
                        value={bannerParaEnviar.textoAlt}
                        onChange={(e) => setBannerParaEnviar({ ...bannerParaEnviar, textoAlt: e.target.value })}
                      />

                      <label>Link que o banner irá redirecionar ao clicado:</label>
                      <input
                        type="text"
                        value={bannerParaEnviar.link}
                        onChange={(e) => setBannerParaEnviar({ ...bannerParaEnviar, link: e.target.value })}
                      />

                      <button className='admin-botaoAzulComHover' onClick={() => adicionarNovoBanner()}>Enviar Novo Banner</button>
                    </div>
                    <div style={{ display: 'flex', gap: '1em', width: '60%' }}>
                      <div className='produtosAdmin-divDoInputDeImagem' style={{ width: '50%', minWidth: '50%' }}>
                        <label>Versão Desktop</label>

                        {(!bannerParaEnviar.imagem) && (
                          <div className="marcasAdmin-upload-area" style={{ height: '-webkit-fill-available' }}>
                            <label htmlFor={"logotipoNovoBannerUpload"} className="marcasAdmin-upload-label" style={{ padding: '4em 7em' }}>
                              <img src={iconEnviarArquivo} alt='' />
                              <span>Escolher arquivo</span>
                              <input id={"logotipoNovoBannerUpload"} type="file" onChange={(e) => handleEditarImagemBannerParaEnviarChange(e)} className="input-file" />
                            </label>
                          </div>
                        )}

                        {bannerParaEnviar.imagem && (
                          <div className="marcasAdmin-upload-area" style={{ border: '1px solid transparent', height: '-webkit-fill-available' }}>
                            <label htmlFor={"logotipoNovoBannerUpload"} className="marcasAdmin-upload-label" style={{ padding: '0', overflow: 'hidden' }}>
                              <input id={"logotipoNovoBannerUpload"} type="file" onChange={(e) => handleEditarImagemBannerParaEnviarChange(e)} className="input-file" style={{ display: 'none' }} />
                              {bannerParaEnviar.imagem && (
                                <img
                                  src={
                                    typeof bannerParaEnviar.imagem === 'string'
                                      ? transformarByteEmImagem(bannerParaEnviar.imagem)
                                      : URL.createObjectURL(bannerParaEnviar.imagem)
                                  }
                                  style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '13em', objectFit: 'contain' }}
                                  alt=""
                                />
                              )}
                            </label>
                          </div>
                        )}

                        <p className='marcasAdmin-auxilioMedidas'>Medidas 320px por 200px</p>
                      </div>

                      <div className='produtosAdmin-divDoInputDeImagem' style={{ width: '30%', minWidth: '40%' }}>
                        <label>Versão Mobile</label>

                        {(!bannerParaEnviar.imagemMobile) && (
                          <div className="marcasAdmin-upload-area" style={{ height: '-webkit-fill-available' }}>
                            <label htmlFor={"logotipoMobileNovoBannerUpload"} className="marcasAdmin-upload-label" style={{ padding: '4em 7em' }}>
                              <img src={iconEnviarArquivo} alt='' />
                              <span>Escolher arquivo</span>
                              <input id={"logotipoMobileNovoBannerUpload"} type="file" onChange={(e) => handleEditarImagemMobileBannerParaEnviarChange(e)} className="input-file" />
                            </label>
                          </div>
                        )}

                        {bannerParaEnviar.imagemMobile && (
                          <div className="marcasAdmin-upload-area" style={{ border: '1px solid transparent', height: '-webkit-fill-available' }}>
                            <label htmlFor={"logotipoMobileNovoBannerUpload"} className="marcasAdmin-upload-label" style={{ padding: '0', overflow: 'hidden' }}>
                              <input id={"logotipoMobileNovoBannerUpload"} type="file" onChange={(e) => handleEditarImagemMobileBannerParaEnviarChange(e)} className="input-file" style={{ display: 'none' }} />
                              {bannerParaEnviar.imagemMobile && (
                                <img
                                  src={
                                    typeof bannerParaEnviar.imagemMobile === 'string'
                                      ? transformarByteEmImagem(bannerParaEnviar.imagemMobile)
                                      : URL.createObjectURL(bannerParaEnviar.imagemMobile)
                                  }
                                  style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '13em', objectFit: 'contain' }}
                                  alt=""
                                />
                              )}
                            </label>
                          </div>
                        )}

                        <p className='marcasAdmin-auxilioMedidas'>Medidas 320px por 200px</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }


            <div className="marcasAdmin-inputsDiv" style={{ alignItems: 'center', borderBottom: '1px solid #CFCFCF' }}>
              <button onClick={() => setMostrarAdicionarBanner(true)} className='admin-botaoAzulComHover' style={{ margin: '3em 0' }}>+ Adicionar novo Banner</button>
            </div>


            <div>
              <div className="marcasAdmin-inputsDiv" style={{ alignItems: 'center', paddingBottom: '10em' }}>
                <div className='bannersPopups-divTituloBannerNumerado'>
                  <h1 className='bannersPopups-tituloBannerNumerado'>Pop-up</h1>
                  <button onClick={() => { setMostrarPopupDeletarPopup(true); setMostrarPopupDeletarBanner(false); setMostrarPopupReordenarBanners(false) }}><img src={iconDeletarBanner} alt='Deletar banner'></img></button>
                </div>
                <p className='textoInformativoUsoPopup'>Aparece na página inicial na primeira vez que o usuário acessa o site naquele dia, deixando de aparecer na data de expiração definida.</p>
                <div className='produtosAdmin-divMaiorInputs' style={{ justifyContent: 'space-between', padding: '2% 10% 2% 0' }}>
                  <div className='produtosAdmin-divDosInputsDeTexto' style={{ maxWidth: '30%' }}>
                    <label>Texto alternativo (acessibilidade)</label>
                    <input
                      type="text"
                      value={popup.textoAlt}
                      onChange={(e) => setPopup({ ...popup, textoAlt: e.target.value })}
                    />

                    <label>Data de expiração do pop-up</label>
                    <input
                      className='dateTimePickerDePopup'
                      type="datetime-local"
                      value={popup.dataExpiracao}
                      onChange={(e) => setPopup({ ...popup, dataExpiracao: e.target.value })}
                    />

                    <label>Link que o popup irá redirecionar ao clicado:</label>
                    <input
                      type="text"
                      value={popup.link}
                      onChange={(e) => setPopup({ ...popup, link: e.target.value })}
                    />

                    {isPopupNovo === true ? 
                    <button className='admin-botaoAzulComHover' onClick={() => adicionarNovoPopup()}>Enviar Popup</button>
                    :
                    <button className='admin-botaoAzulComHover' onClick={() => salvarPopup()}>Salvar Alterações Popup</button>
                    }
                  </div>
                  <div style={{ display: 'flex', gap: '1em', width: '60%' }}>
                    <div className='produtosAdmin-divDoInputDeImagem' style={{ width: '50%', minWidth: '50%' }}>
                      <label>Imagem do Pop-up</label>

                      {(!popup.imagem) && (
                        <div className="marcasAdmin-upload-area" style={{ height: '-webkit-fill-available' }}>
                          <label htmlFor={"logotipoNovoBannerUpload"} className="marcasAdmin-upload-label" style={{ padding: '4em 7em' }}>
                            <img src={iconEnviarArquivo} alt='' />
                            <span>Escolher arquivo</span>
                            <input id={"logotipoNovoBannerUpload"} type="file" onChange={(e) => handleEditarImagemPopup(e)} className="input-file" />
                          </label>
                        </div>
                      )}

                      {popup.imagem && (
                        <div className="marcasAdmin-upload-area" style={{ border: '1px solid transparent', height: '-webkit-fill-available' }}>
                          <label htmlFor={"logotipoNovoBannerUpload"} className="marcasAdmin-upload-label" style={{ padding: '0', overflow: 'hidden' }}>
                            <input id={"logotipoNovoBannerUpload"} type="file" onChange={(e) => handleEditarImagemPopup(e)} className="input-file" style={{ display: 'none' }} />
                            {popup.imagem && (
                              <img
                                src={
                                  typeof popup.imagem === 'string'
                                    ? transformarByteEmImagem(popup.imagem)
                                    : URL.createObjectURL(popup.imagem)
                                }
                                style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '13em', objectFit: 'contain' }}
                                alt=""
                              />
                            )}
                          </label>
                        </div>
                      )}

                      <p className='marcasAdmin-auxilioMedidas'>Medidas 320px por 200px</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}
export default BannersPopupsAdmin;