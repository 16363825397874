import Header from '../../componentes/Header';
import { useLocation } from 'react-router-dom';
import GarantiaForm from '../../componentes/Garantia/GarantiaForm';
function Garantia() {
  const location = useLocation();
  const itensSelecionados = location && location.state ? location.state.itensSelecionados : [];
  const notaFiscal = location && location.state ? location.state.notaFiscal : [];
  return (
      <div className="contenteudo">
        <Header logado={true}/>
        <GarantiaForm itensSelecionados={itensSelecionados} notaFiscal={notaFiscal}/>
    </div>
  );
}
export default Garantia;