import Azul from './imgs/Azul.png';
import iconCheck from './imgs/iconValidoVerde.png';
import iconCheckAzul from './imgs/iconValidoAzul.png';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../componentes/Header';
import './procotoloCriadoPage.css'
import { useState, useEffect } from 'react';
function ProtocoloCriadoPage() {
  const location = useLocation();
  const [mobile, setMobile] = useState(window.innerWidth <= 850);

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const data1 = location && location.state ? location.state.data1 : [];
  var numeroDoProtocolo = 0;
  if (data1.protocolo) {
    numeroDoProtocolo = data1.protocolo.id;
  }
  var urlCompleto = window.location.href;
  var devolucao = null;
  if (urlCompleto.includes("devolucao")) {
    devolucao = true;
  } else {
    devolucao = false;
  }
  return (
    <div className="contenteudo">
      <Header logado={true} />
      <div>
        <div className='devolucaoDoc' style={{ display: "flex", justifyContent: "center" }}>
          <div className='whiteBoxDoc'>
            {!mobile ? (
              devolucao ? (
                <h1>
                  Novo Protocolo de Devolução
                </h1>
              ) : (
                <h1>
                  Novo Protocolo de Garantia
                </h1>
              )
            ) : (
              <div style={{ display: 'flex', gap: '1em', flexDirection: 'column', marginTop: '1em', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginLeft: '5%' }}>
                  <div className='indicacaoDeEtapaAtualNumeroMobile'>4</div>
                  <label className='labelDoIndicacaoDeEtapaAtualNumeroMobile'>Validação</label>
                </div>
                <div className='barraMaiorIndicacaoMobile'>
                  <div style={{ width: '100%', backgroundColor: "#006F1C" }} className='barraMenorIndicacaoMobile' />
                </div>
              </div>
            )}
            {!mobile ? (
              <div>
                <div className='progressBarDoc'>
                  <span className='progressFillDoc' style={{ backgroundColor: '#006F1C', width: '100%' }}></span>
                </div>
                <div className='progressStageDoc'>
                  <div className='stagesDoc'>
                    <img src={iconCheck} alt='' />
                    <h2 style={{ color: '#006F1C' }} >Seleção de Itens</h2>
                  </div>

                  <div className='stagesDoc'>
                    <img src={iconCheck} alt='' />
                    <h2 style={{ color: '#006F1C' }} >Informações gerais</h2>
                  </div>

                  <div className='stagesDoc'>
                    <img src={iconCheck} alt='' />
                    <h2 style={{ fontSize: 14, color: '#006F1C' }}>Documentação</h2>
                  </div>
                  <div className='stagesDoc'>
                    <h2 style={{ color: 'rgb(255, 255, 255)', zIndex: '2', bottom: '0.9px', left: '25.3px', position: 'relative' }}>4</h2>
                    <img src={Azul} alt='' />
                    <h2 style={{ color: '#003594', fontWeight: '700' }}>Validação</h2>
                  </div>
                </div>
              </div>
            ) : null}
            <div className='BottomBox' style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
              <div className='whiteBoxMenor'>
                <div className='superiorDaWhiteboxMenor'>
                  <img alt='' src={iconCheckAzul} style={{ width: '2lh', height: '2lh' }}></img>
                  <h2>Protocolo criado com sucesso!</h2>
                </div>
                <div className='meioDaWhiteboxMenor'>
                  <h3>Protocolo número: {numeroDoProtocolo}</h3>
                  {devolucao ? (
                    <div>Status: aguardar validação da Nota de Devolução</div>
                  ) : (
                    <div>Status: aguardar validação da Nota de Garantia</div>
                  )}
                </div>
                <div className='inferiorDaWhiteboxMenor'>
                  <Link to={'/abrirNovoProtocolo'} className='botaoAbrirNovoProtooloMobile' style={{ width: 'fit-content', textDecoration: 'underline' }}>Abrir novo protocolo</Link>
                  <Link to={'/ListaDeProtocolos'} className='botaoVerListaDeProtocolosMobile' style={{ width: 'fit-content' }}><div>Ver lista de protocolos</div></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div>
  );
}
export default ProtocoloCriadoPage;