import React, { useEffect, useState } from 'react';

const UltimosPostsInstagram = () => {
  const [postagensInstagram, setPostagensInstagram] = useState([]);

  // Requisitar postagens mais recentes do Instagram
  useEffect(() => {
    const fetchInstagramPosts = async () => {
      const url = 'https://instagram-scraper-api2.p.rapidapi.com/v1.2/posts?username_or_id_or_url=girospecas&url_embed_safe=true';
      const options = {
        method: 'GET',
        headers: {
          'x-rapidapi-key': '9d998f878emshacfc55b47487719p1b8f08jsn586ae420d27e',
          'x-rapidapi-host': 'instagram-scraper-api2.p.rapidapi.com'
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        const formattedPosts = data.data.items.map((item) => ({
          id: item.id,
          media_type: item.media_type,
          media_url: item.thumbnail_url,
          permalink: `https://www.instagram.com/p/${item.code}`,
          caption: item.caption.text,
        }));
        setPostagensInstagram(formattedPosts.slice(0, 4));
      } catch (error) {
        console.error(error);
      }
    };

    fetchInstagramPosts();
  }, []);
  return (
    <>
      {postagensInstagram &&
        <div className="postsInstagram">
          {postagensInstagram.map((post) => (
            <a style={{ display: 'contents' }} key={post.id} href={post.permalink} target="_blank" rel="noopener noreferrer">
              <div className="itemPostInstagram">
                <img src={post.media_url} alt={post.caption} />
              </div>
            </a>
          ))}
        </div>
      }
    </>
  )
}
export default UltimosPostsInstagram;