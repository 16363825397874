import Header from '../../../componentes/Header';
import React, { useState, useEffect } from 'react';
import './Produtos.css';
import BottomSitePublico from '../../../componentes/BottomSitePublico';
import Cookies from 'js-cookie';
import ComponenteListaProdutos from '../../../componentes/ComponenteListaProdutos';

function Produtos() {
  // Estado geral
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });
  // Efeito para verificar autenticação
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setStatusUsuario({ logado: true, admin: false });
      const fetchData = async () => {
        try {
          const headers = new Headers({ 'Authorization': `Bearer ${token}` });
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, { headers });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });
        } catch (error) {
          setStatusUsuario({ logado: false, admin: false });
        }
      };
      fetchData();
    } else {
      setStatusUsuario({ logado: false, admin: false });
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sitePublicoDivMaior">
      <Header logado={statusUsuario.logado ? true : false} admin={statusUsuario.admin ? true : false} />
      <ComponenteListaProdutos versaoMini={false} />
      <BottomSitePublico />
    </div >
  );
}

export default Produtos;
