import Header from '../../../componentes/Header';
import React, { useState, useEffect } from 'react';
import './HomeAdmin.css';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import bannersPopupsIcon from './imgs/bannersPopupsAdminIcon.svg';
import produtosIcon from './imgs/produtosAdminIcon.svg';
import marcasIcon from './imgs/marcasAdminIcon.svg';
import linhasIcon from './imgs/linhasAdminIcon.svg';
import usuariosIcon from './imgs/usuariosAdminIcon.svg';

const icons = [
  { src: bannersPopupsIcon, label: 'Banners e Pop-ups', linkPagina: '/admin/bannersPopups' },
  { src: produtosIcon, label: 'Produtos', linkPagina: '/admin/listagemProdutos' },
  { src: marcasIcon, label: 'Marcas', linkPagina: '/admin/listagemMarcas' },
  { src: linhasIcon, label: 'Linhas', linkPagina: '/admin/listagemLinhas' },
  { src: usuariosIcon, label: 'Usuários', linkPagina: '/admin/listagemUsuarios' },
];

function HomeAdmin() {
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });
  const navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, {
            headers: { 'Authorization': `Bearer ${token}` },
          });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });

          if (!data) navigate('/login');
        } catch {
          setStatusUsuario({ logado: false, admin: false });
          navigate('/login');
        }
      };
      fetchData();
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="homeAdmin-wrapper">
      <Header logado={statusUsuario.logado} admin={statusUsuario.admin} />
      <div className="homeAdmin-content">
        <h1 className="homeAdmin-title">Administrador</h1>
        <div className="homeAdmin-cards">
          {icons.map((icon, index) => (
            <Link to={icon.linkPagina} style={{display: 'contents'}} key={index}>
              <div className="homeAdmin-card">
                <img src={icon.src} alt={icon.label} />
                <h2>{icon.label}</h2>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomeAdmin;