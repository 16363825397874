import Header from '../../componentes/Header';
import { useLocation } from 'react-router-dom';
import Blue from '../../imagensPaginasEnviarArquivos/Blue.png'
import iconFile from '../../imagensPaginasEnviarArquivos/iconFile.png'
import icon4 from '../../imagensPaginasEnviarArquivos/icon4unselect.png'
import iconCheck from '../../imagensPaginasEnviarArquivos/iconOk.png'
import iconInfo from '../../imagensPaginasEnviarArquivos/iconInfo.png'
import fileExample from '../../imagensPaginasEnviarArquivos/fileExample.png';
import iconDownload from '../../imagensPaginasEnviarArquivos/iconDownload.svg';
import iconFilePDF from '../../imagensPaginasEnviarArquivos/IconFilePDF.png';
import iconDeleteFile from '../../imagensPaginasEnviarArquivos/IconDeleteFile.png';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import InfoBox from '../../componentes/InfoBox';
import Cookies from 'js-cookie';
function EnviarComprovanteGastosGarantiaPage() {
  const location = useLocation();
  const idDaGarantia = location && location.state ? location.state.idDaGarantia : [];
  const data1 = location && location.state ? location.state.data1 : [];

  const [mobile, setMobile] = useState(window.innerWidth <= 850);

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [valor, setValor] = useState([]);
  const [descricao, setDescricao] = useState([]);
  const navigate = useNavigate();
  const urlCompleto = window.location.href;
  const partesUrl = urlCompleto.split('/');
  partesUrl.pop();
  const urlAnterior = partesUrl.join('/');

  const fileInputRef = useRef(null);
  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    const formattedValue = inputValue.replace(/[^\d.,]/g, '');

    e.target.value = formattedValue;
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFiles([...selectedFiles, file]);
  };
  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const toggleInfoBox = () => {
    setShowInfoBox(!showInfoBox);
  };
  const handleValorChange = (index, event) => {
    setValor(prevValor => {
      const newValue = event.target.value;
      const updatedValor = [...prevValor];
      updatedValor[index] = newValue;
      return updatedValor;
    });
  };

  const handleDescricaoChange = (index, event) => {
    setDescricao(prevDescricao => {
      const newDescricao = event.target.value;
      const updatedDescricao = [...prevDescricao];
      updatedDescricao[index] = newDescricao;
      return updatedDescricao;
    });
  };
  const proximaPagina = async () => {
    const token = Cookies.get('token');

    if (!token) {
      console.error('Token não encontrado no localStorage');
      return;
    }
    let response2 = null;
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('arquivos', selectedFiles[i]);
        formData.append('valores', valor[i]);
        formData.append('descricoes', descricao[i]);
      }
      response2 = await fetch(`${process.env.REACT_APP_API_URL}/garantia/upload-comprovante-de-gastos/${idDaGarantia}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response2.ok) {
        console.error('Erro na requisição de upload de comprovante:', response2.statusText);
        return;
      }

      if (response2 != null) {
        if (response2.ok) {
          navigate('protocoloCriado', { state: { data1 } });
        } else {
          alert("Ocorreu um erro")
        }
      } else {
        alert("Ocorreu um erro")
      }
    }
  }
  const baixarModeloDeOrcamento = () => {
    const link = document.createElement('a');
    link.href = fileExample;
    link.download = 'modelo-orçamento.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="contenteudo">
      <Header logado={true} />
      <div className='devolucaoDoc' style={{ display: "flex", justifyContent: "center" }}>
        {mobile ? (
          <div style={{ display: 'flex', gap: '1em', flexDirection: 'column', marginTop: '1em', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginLeft: '5%' }}>
              <div className='indicacaoDeEtapaAtualNumeroMobile'>3</div>
              <label className='labelDoIndicacaoDeEtapaAtualNumeroMobile'>Documentação</label>
            </div>
            <div className='barraMaiorIndicacaoMobile'>
              <div style={{ width: '80%' }} className='barraMenorIndicacaoMobile' />
            </div>
          </div>
        ) : null}
        {showInfoBox && (
          <InfoBox toggleInfoBox={toggleInfoBox} garantia={true} />
        )}
        <div className='whiteBoxDoc'>
          {!mobile ? (
            <h1 className='h1Title'>
              Novo Protocolo de Garantia
            </h1>
          ) : null}
          {!mobile ? (
            <div>
              <div className='progressBarDoc'>
                <span className='progressFillDoc'></span>
              </div>
              <div className='progressStageDoc'>
                <div className='stagesDoc'>
                  <img src={iconCheck} alt='' />
                  <h2>Seleção de Itens</h2>
                </div>

                <div className='stagesDoc'>
                  <img src={iconCheck} alt='' />
                  <h2>Informações gerais</h2>
                </div>

                <div className='stagesDoc'>
                  <h2 style={{ color: 'rgb(255, 255, 255)', zIndex: '2', bottom: '0.9px', left: '25.3px', position: 'relative' }}>3</h2>
                  <img src={Blue} alt='' />
                  <h2 style={{ fontWeight: 700, fontSize: 14, color: "#041E42" }}>Documentação</h2>
                </div>

                <div className='stagesDoc'>
                  <img src={icon4} alt='' />
                  <h2>Validação</h2>
                </div>
              </div>
            </div>
          ) : null}
          <hr className="verticalLineBoxDoc" />
          <div className='BottomBox'>
            <h1 className='h1Middle'>
              Anexar Comprovantes de gastos
            </h1>
            <div className='div2'>
              <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris faucibus sem eu euismod pretium. Aliquam sed vehicula velit. Pellentesque et ex vitae dui consectetur hendrerit.</h2>
              <div className='anexarArquivo' onClick={handleClick}>
                <input type='file' onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
                <img src={iconFile} alt='' />
                <h1>Anexar Arquivo</h1>
              </div>
              {mobile ? (
                <div className='infoDivRightButton2' onClick={baixarModeloDeOrcamento}>
                  <img className='infoDivRightImg' src={iconDownload} alt="" />
                  <h1 className='infoDivRightH1'>Baixar modelo de orçamento</h1>
                </div>
              ) : null}
              {mobile ? (
                <div>
                  <div className='divInfo' onClick={toggleInfoBox}>
                    <img src={iconInfo} className='imgInfo' alt='Icone de Informação' />
                    <h1 className='h1Info'>Preciso de ajuda</h1>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            {mobile ? (
              <>
                <hr className="verticalLineBoxDocProof" />
                <h2 style={{ margin: 0 }} className='tituloDocumentosAnexados'>Documentos Anexados:</h2>
              </>
            ) : null}
            <hr className="verticalLineBoxDocProof" />
            {selectedFiles.map((file, index) => (
              <><div key={index} className='divPaiDaListaDeComprovantesAnexados'>
                <div className='divFileProof'>
                  <label style={{ display: 'flex', gap: '10px' }}>
                    <img alt='' style={{ width: '18px', height: '22px' }} src={iconFilePDF} />{file.name}
                  </label>
                  <div style={{ cursor: 'pointer' }} onClick={() => handleRemoveFile(index)}><img alt='' style={{ width: '16px', height: '16px' }} src={iconDeleteFile} /></div>
                </div>
                <div className='divPriceDescribe'>
                  <div className='divPriceProof'>
                    <label htmlFor='priceInput'>Valor</label>
                    <div className='divPriceProofInput'>
                      <span className='currencySymbol'>R$</span>
                      <input
                        id='priceInput'
                        className='divPriceProofInputText'
                        type="text"
                        onInput={handleInputChange}
                        value={valor[index] || ''}
                        onChange={(event) => handleValorChange(index, event)}
                      />
                    </div>
                  </div>

                  <div className='divDaDescricaoDeGastosAdicionais'>
                    <label className='divDescribeProof'>
                      Descrição do Gasto
                      <input
                        className='divDescribeProofInput'
                        type="text"
                        value={descricao[index] || ''}
                        onChange={(event) => handleDescricaoChange(index, event)}
                      />
                    </label>
                  </div>
                </div>
              </div><hr className="verticalLineBoxDocProof" /></>
            ))
            }
          </div>
          {!mobile ? (<div className='BottomBox'>
            <div className='divBackFoward' style={{ display: 'flex', paddingBottom: '2lh', marginTop: '2lh', justifyContent: 'space-between' }}>
              <Link to={urlAnterior} style={{ width: 'fit-content', height: 'fit-content' }}>
                <div className='buttonBack' style={{ cursor: 'pointer' }}>
                  <h1 style={{ fontSize: '15px' }}>❮</h1>
                  <h1 style={{ fontSize: '15px' }}>Voltar</h1>
                </div>
              </Link>
              <div className='divInfo' onClick={toggleInfoBox}>
                <img src={iconInfo} className='imgInfo' alt='Icone de Informação' />
                <h1 className='h1Info'>Preciso de ajuda</h1>
              </div>
              <div className='buttonFoward' onClick={proximaPagina} style={{ cursor: 'pointer' }}>
                <h1 style={{ color: '#fff', fontSize: '15px' }}>Prosseguir</h1>
                <h1 style={{ color: '#fff', fontSize: '15px' }}>❯</h1>
              </div>
            </div>
          </div>) : (
            <div className='botoesInferiores' style={{ position: 'fixed' }}>
              <Link to={urlAnterior} style={{ width: 'fit-content', height: 'fit-content' }}>
                <div className='botaoCancelar'><label>❮</label><label>Voltar</label></div>
              </Link>
              <div onClick={proximaPagina} style={{ width: 'fit-content', height: 'fit-content', cursor: 'pointer' }}>
                <div className='botaoProsseguir'>
                  <label style={{ cursor: 'pointer' }}>Prosseguir</label>
                  <label style={{ cursor: 'pointer' }}>❯</label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default EnviarComprovanteGastosGarantiaPage;