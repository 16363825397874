import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Dropdown } from 'primereact/dropdown';
import Header from '../../../componentes/Header';
import BottomSitePublico from '../../../componentes/BottomSitePublico';
import iconeInstagram from './imgs/iconInstagram.svg';
import iconYoutube from './imgs/iconYoutube.svg';
import './Conteudos.css';
import UltimosPostsInstagram from '../../../componentes/UltimosPostsInstagram';

const playlists = [
  {
    title: 'Filtrar por categoria',
  },
  {
    id: 'PLUWMzBJqh5sDfybwfADtLyrv09I7xqBIk',
    title: 'Mesa Redonda',
  },
  {
    id: 'PLUWMzBJqh5sDcuAdK2kkfeA9zkl8o-2Bl',
    title: 'Giro na Giros',
  },
  {
    id: 'PLUWMzBJqh5sAPQEFXRe1Yenvyv_RVX1rz',
    title: 'Dicas Técnicas',
  },
  {
    id: 'PLUWMzBJqh5sCvHr48CzcoL9niuRtFxIWv',
    title: 'Conheça a Giros Peças!',
  },
  {
    id: 'PLUWMzBJqh5sAEdAHFIobQkwFhNybDIuuV',
    title: 'Giros na AUTOMEC',
  },
  {
    id: 'PLUWMzBJqh5sC12iG63XFKreZi6CRlxoEF',
    title: 'Giros na Reparasul',
  },
  {
    id: 'PLUWMzBJqh5sC9aKjUtueDDArfMTmcOSnQ',
    title: 'É a Giros na Porsche Cup',
  },
  {
    id: 'PLUWMzBJqh5sDdYj1fevvjxdtFlKX4d_kS',
    title: 'É a Giros na FÓRMULA TRUCK',
  },
];

const Conteudos = () => {
  const [mobile, setMobile] = useState(window.innerWidth <= 850);
  const [linhaSelecionadaFiltro, setLinhaSelecionadaFiltro] = useState('');
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });
  const [videos, setVideos] = useState({});
  useEffect(() => {
    const handleResize = () => setMobile(window.innerWidth <= 850);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const fetchData = async () => {
        try {
          const headers = new Headers({ 'Authorization': `Bearer ${token}` });
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, { headers });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });
        } catch {
          setStatusUsuario({ logado: false, admin: false });
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchVideos = async (playlistId) => {
      try {
        const maxResults = 3;
        const youtubeKeyApi = "AIzaSyASqCfOiaumlkRtjDYkkDJhCsc_JQlbqKY";
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=${maxResults}&key=${youtubeKeyApi}`
        );
        const data = await response.json();
        setVideos(prevVideos => ({ ...prevVideos, [playlistId]: data.items }));
      } catch (error) {
        console.error('Erro ao buscar vídeos:', error);
      }
    };

    playlists.forEach(playlist => playlist.id ? fetchVideos(playlist.id) : null);
  }, [mobile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sitePublicoDivMaior">
      <Header logado={statusUsuario.logado ? true : false} admin={statusUsuario.admin ? true : false} />
      <div className="divMaiorMobileSitePublico">
        <div className="divFundoAzulYoutube" style={{ marginTop: '1em', padding: '3em 5.8%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{ width: '-webkit-fill-available', gap: '1.5em', display: 'flex', justifyContent: 'space-between', alignItems: mobile ? 'flex-start' : 'center', flexDirection: mobile ? 'column-reverse' : 'row' }}>
            <div style={{ display: 'flex', flexDirection: mobile ? 'column' : 'row', alignItems: mobile ? 'flex-start' : 'center', gap: '8%', width: mobile ? '100%' : '80%' }}>
              <div>
                <h1 className="catalogoDeProdutosTitulo" style={{ margin: '0' }}>Conteúdos & Dicas</h1>
                <h2 className="subtituloYoutube">Acompanhe nosso canal no Youtube!</h2>
              </div>
              <Dropdown
                className="dropdownSelecionarLinhaCatProdutos"
                style={{ maxWidth: !mobile ? 'fit-content' : '100%', width: '-webkit-fill-available', gap: '5em' }}
                value={linhaSelecionadaFiltro}
                onChange={(e) => setLinhaSelecionadaFiltro(e.value)}
                options={playlists}
                optionLabel="title"
                placeholder="Filtrar por categoria"
                panelClassName="abasDropdownFiltrosSitePublico"
              />
            </div>
            <img src={iconYoutube} alt="YouTube" />
          </div>
        </div>

        {playlists
          .filter(playlist => 
            linhaSelecionadaFiltro.title === 'Filtrar por categoria' ||
            !linhaSelecionadaFiltro.title ? true : playlist.title.startsWith(linhaSelecionadaFiltro.title)
          )
          .map(playlist => (
            videos[playlist.id] && (
              <div key={playlist.id}>
                <h2 className="tituloListaConteudos">{playlist.title}</h2>
                <div className="listaDeVideos" style={{ marginTop: '2em' }}>
                  {videos[playlist.id].map(video => (
                    <a
                      style={{ display: 'contents' }}
                      href={`https://www.youtube.com/watch?v=${video.snippet.resourceId.videoId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={video.id}
                    >
                      <div className="itemVideoDoYoutubeConteudos">
                        <img
                          style={{ width: '100%', height: mobile ? '52vw' : '19vw', objectFit: 'cover', borderRadius: '8px 8px 0 0' }}
                          src={video.snippet.thumbnails.high?.url || video.snippet.thumbnails.default.url}
                          alt={video.snippet.title}
                        />
                        <h3 style={{ margin: '0.8em', fontSize: '1.2em' }} className="tituloVideoYoutube">
                          {video.snippet.title}
                        </h3>
                      </div>
                    </a>
                  ))}
                </div>
                <div className="botaoVerTodosConteudos">
                  <a
                    href={`https://www.youtube.com/playlist?list=${playlist.id}`}
                    title={`Ir para a playlist completa de ${playlist.title} no youtube`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver todos de {playlist.title}
                  </a>
                </div>
              </div>
            )
          ))}
        <div className="divDoBanner4Instagram" style={{ marginBottom: '2em' }}>
          <div className="divTituloBannerInstagram">
            <h1 className="tituloBannerInstagram">Já conhece o nosso Instagram?</h1>
            <a key="linkInstagramCompleto" href="https://www.instagram.com/girospecas" target="_blank" rel="noopener noreferrer" className="botaoInstagramGirosPecas">
              <img alt="iconeDoInstagram" src={iconeInstagram} />
              girospecas
            </a>
          </div>
          <UltimosPostsInstagram/>
        </div>
        <BottomSitePublico />
      </div>
    </div>
  );
};

export default Conteudos;
