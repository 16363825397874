import Header from '../../../componentes/Header';
import React, { useState } from 'react';
import './SitePublico.css';
import iconePassarBanner from './imgs/iconePassarBanner.svg';
import { Link } from 'react-router-dom';
import iconeDoYoutube from './imgs/iconeYoutube.svg';
import { useEffect } from 'react';
import iconeInstagram from './imgs/iconInstagram.svg';
import Cookies from 'js-cookie';
import BottomSitePublico from '../../../componentes/BottomSitePublico';

import imagemUnidadePortoAlegre from './imgs/imagemUnidadePortoAlegre.png';
import imagemUnidadeChapeco from './imgs/imagemUnidadeChapeco.png';
import imagemUnidadeCuritiba from './imgs/imagemUnidadeCuritiba.png';
import imagemUnidadePalhoca from './imgs/imagemUnidadePalhoca.png';
import iconMelhoresPrecos from './imgs/iconMelhoresPrecos.svg';
import iconSuporte from './imgs/iconSuporte.svg';
import iconFreteNeutro from './imgs/iconFreteNeutro.svg';
import iconGarantias from './imgs/iconGarantias.svg';

import ComponenteListaProdutos from '../../../componentes/ComponenteListaProdutos';
import UnidadeDistribuidora from '../../../componentes/UnidadeDistribuidora';
import BannerGptw from '../../../componentes/BannerGptw';
import BannerVantagensGiros from '../../../componentes/BannerVantagensGiros';

import UltimosPostsInstagram from '../../../componentes/UltimosPostsInstagram';

import iconOpiniaoCliente from './imgs/iconOpiniaoCliente.svg';

import logoAntroi from './imgs/PrincipaisMarcas/LogoAntroi.jpg';
import logoAutoShine from './imgs/PrincipaisMarcas/LogoAutoShine.jpeg';
import logoBBBValvulas from './imgs/PrincipaisMarcas/LogoBBBValvulas.jpeg';
import logoIllinois from './imgs/PrincipaisMarcas/LogoIllinois.jpeg';
import logoOlimpia from './imgs/PrincipaisMarcas/LogoOlimpia.jpeg';
import logoParaflu from './imgs/PrincipaisMarcas/LogoParaflu.png';
import logoRioSulense from './imgs/PrincipaisMarcas/LogoRioSulense.png';
import logoSchadek from './imgs/PrincipaisMarcas/LogoSchadek.jpeg';
import logoSpaal from './imgs/PrincipaisMarcas/LogoSpaal.png';
import logoSuloy from './imgs/PrincipaisMarcas/LogoSuloy.png';
import logoTakao from './imgs/PrincipaisMarcas/LogoTakao.png';
import logoRicComponentes from './imgs/PrincipaisMarcas/LogoRicComponentes.png';
import logoDynamic from './imgs/PrincipaisMarcas/LogoDynamic.png';
import logoOrbiQuimica from './imgs/PrincipaisMarcas/logoOrbiQuimica.jpg';
import logoSintech from './imgs/PrincipaisMarcas/LogoSintech.jpg';
import logoKolbenschmidt from './imgs/PrincipaisMarcas/LogoKolbenschmidt.jpg';
import logoFrontier from './imgs/PrincipaisMarcas/LogoFrontier.jpg';
import logoAutolinea from './imgs/PrincipaisMarcas/LogoAutolinea.jpg';
import logoAbr from './imgs/PrincipaisMarcas/LogoAbr.jpg';
import logoTaranto from './imgs/PrincipaisMarcas/LogoTaranto.jpg';

function SitePublico() {
  const principaisMarcasList = ([
    { imagem: logoAntroi, alt: 'Antroi' },
    { imagem: logoAutoShine, alt: 'Auto Shine' },
    { imagem: logoBBBValvulas, alt: 'BBB Válvulas' },
    { imagem: logoIllinois, alt: 'Illinois' },
    { imagem: logoOlimpia, alt: 'Olimpia' },
    { imagem: logoParaflu, alt: 'Paraflu' },
    { imagem: logoRioSulense, alt: 'Rio Sulense' },
    { imagem: logoSchadek, alt: 'Schadek' },
    { imagem: logoSpaal, alt: 'Spaal' },
    { imagem: logoSuloy, alt: 'Suloy' },
    { imagem: logoTakao, alt: 'Takao' },
    { imagem: logoRicComponentes, alt: 'Ric Componentes' },
    { imagem: logoDynamic, alt: 'Dynamic Retentores' },
    { imagem: logoOrbiQuimica, alt: 'Orbi Química' },
    { imagem: logoSintech, alt: 'Sintech' },
    { imagem: logoKolbenschmidt, alt: 'Kolbenschmidt' },
    { imagem: logoFrontier, alt: 'Frontier' },
    { imagem: logoAutolinea, alt: 'Autolinea' },
    { imagem: logoAbr, alt: 'ABR' },
    { imagem: logoTaranto, alt: 'Taranto' },
  ]);
  const [mobile, setMobile] = useState(window.innerWidth <= 850);

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });


  const [videos, setVideos] = useState([]);
  //Verificar se o usuário está logado e se é admin
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setStatusUsuario({ logado: true, admin: false });
      const fetchData = async () => {
        try {
          if (token) {
            const headers = new Headers({
              'Authorization': `Bearer ${token}`,
            });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, { headers });
            const data = await response.json();
            setStatusUsuario({ logado: true, admin: data });
          }
        } catch (error) {
          setStatusUsuario({ logado: false, admin: false });
        }
      };

      fetchData();
    } else {
      setStatusUsuario({ logado: false, admin: false });
    }
  }, []);
  //Requisitar vídeos mais recentes do youtube
  useEffect(() => {
    async function fetchVideos() {
      try {
        var maxResults = 8;
        if (mobile) {
          maxResults = 2;
        }
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCvtqlPbt64gm1HklxQmoizA&maxResults=${maxResults}&order=date&type=video&key=AIzaSyAlOq6nnlmZvgdL5jc0ZcDqJpScZGnTeDY`
        );
        const data = await response.json();
        setVideos(data.items);
      } catch (error) {
        console.error('Erro ao buscar vídeos:', error);
      }
    }

    fetchVideos();
  }, [mobile]);

  //Sistemas dos Popups

  const [mostrarPopups, setMostrarPopups] = useState(true);
  const [popups, setPopups] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/popups/listarPopups`);

        if (!response.ok) {
          throw new Error(`Erro na resposta do servidor: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        setPopups(data);
      } catch (error) {
        console.log("Erro ao requisitar banners", error);
      }
    };

    fetchData();
  }, []);


  //Sistema de Banners
  const [banners, setBanners] = useState([]);
  const [indexBanner, setIndexBanner] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/banner/listarBanners`);

        if (!response.ok) {
          throw new Error(`Erro na resposta do servidor: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();

        setBanners(data);
      } catch (error) {
        console.log("Erro ao requisitar banners", error);
      }
    };

    fetchData();
  }, []);

  // Função para transformar byte em imagem
  function transformarByteEmImagem(itemData) {
    const byteCharacters = atob(itemData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    return url;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sitePublicoDivMaior">
      {popups ? (
        <div className='divMaiorPopup' style={{ display: mostrarPopups ? 'flex' : 'none' }}>
          <div className='divMenorPopup'>
            <button className='botaoFecharPopup' onClick={() => setMostrarPopups(false)}>✖</button>
            {popups.link ? (
              <a href={popups.link} target="_blank" rel="noopener noreferrer" style={{ display: 'contents' }}>
                <img src={transformarByteEmImagem(popups.imagem)} alt={popups.textoAlt} />
              </a>
            ) : (
              <img src={transformarByteEmImagem(popups.imagem)} alt={popups.textoAlt} />
            )}
          </div>
        </div>
      ) : null}
      <Header logado={statusUsuario.logado ? true : false} admin={statusUsuario.admin ? true : false} />
      <div className='divMaiorMobileSitePublico'>
        {banners[indexBanner] && (
          <div className='bordaDoBannerSuperior'>
            {banners.length > 1 && <button className='botaoTrocarBanner' style={{ position: 'absolute', left: '2%' }} onClick={() => setIndexBanner((indexBanner - 1 + banners.length) % banners.length)}><img style={{ transform: 'scaleX(-1)' }} src={iconePassarBanner} alt=''></img></button>}

            {banners[indexBanner].link ? (
              <Link target="_blank" rel="noopener noreferrer" to={banners[indexBanner].link !== '' ? banners[indexBanner].link : null} style={{ display: 'contents' }}>
                <img alt={banners[indexBanner].textoAlt} className='bannerSuperiorSitePublico' src={banners[indexBanner].imagemMobile && mobile ? transformarByteEmImagem(banners[indexBanner].imagemMobile) : transformarByteEmImagem(banners[indexBanner].imagem)}></img>
              </Link>
            ) :
              <img alt={banners[indexBanner].textoAlt} className='bannerSuperiorSitePublico' src={banners[indexBanner].imagemMobile && mobile ? transformarByteEmImagem(banners[indexBanner].imagemMobile) : transformarByteEmImagem(banners[indexBanner].imagem)}></img>
            }

            {banners.length > 1 && <button className='botaoTrocarBanner' style={{ position: 'absolute', right: '2%' }} onClick={() => setIndexBanner((indexBanner + 1 + banners.length) % banners.length)}><img src={iconePassarBanner} alt=''></img></button>}
          </div>
        )}
        <ComponenteListaProdutos versaoMini={true} />
        <div className='divBotaoVerTodosProdutos'><button><Link to={"/produtos"}>Ver todos os Produtos</Link></button></div>
        {!mobile ?
          <div className='listaDeBeneficiosGiros'>
            <div className='itemBeneficiosGiros'>
              <img src={iconMelhoresPrecos} alt=''></img>
              <div>
                <h3>MELHORES PREÇOS</h3>
                <h4>Promoções incríveis</h4>
              </div>
            </div>
            <div className='itemBeneficiosGiros'>
              <img src={iconSuporte} alt=''></img>
              <div>
                <h3>SUPORTE</h3>
                <h4>Conte conosco</h4>
              </div>
            </div>
            <div className='itemBeneficiosGiros'>
              <img src={iconFreteNeutro} alt=''></img>
              <div>
                <h3>FRETE NEUTRO</h3>
                <h4>O planeta agradece</h4>
              </div>
            </div>
            <div className='itemBeneficiosGiros'>
              <img src={iconGarantias} alt=''></img>
              <div>
                <h3>GARANTIAS</h3>
                <h4>e suporte</h4>
              </div>
            </div>
          </div>
          : null}
        <BannerVantagensGiros />
        <div className='divParaMostrarAsUnidades'>
          <h1 className='tituloUnidades'>Conheça nossas unidades</h1>
          <UnidadeDistribuidora
            titulo='DISTRIBUIDORA E LOJA'
            local='PORTO ALEGRE'
            endereco='Av. Cairu, 1045 - Porto Alegre/RS'
            descricao1='Inaugurada em 2018, nossa loja conta com diversos produtos entre peças e acessórios para seu veículo.'
            descricao2='A distribuidora conta com infra-estrutura de mais de 2.500 m² possuindo mais de 14 mil itens em estoque.'
            imgSrc={imagemUnidadePortoAlegre}
            mobile={mobile}
            reverse={false}
            vejaComoChegarUrl={'https://www.google.com/maps/dir//Av.+Cairú,+1045+-+Navegantes,+Porto+Alegre+-+RS,+90230-030/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x951979eb8eaf7999:0x5de1dd18d137e62c?sa=X&ved=1t:707&ictx=111'}
          />
          <UnidadeDistribuidora
            titulo='DISTRIBUIDORA'
            local='PALHOÇA'
            endereco='Av. Ivo Lucchi, 207 - Palhoça/SC'
            descricao1='Localizada na Av. Ivo Lucchi, 207 - Palhoça - SC e Inaugurada em 2015, a distribuidora conta com mais de 500 m² com diversos itens para motores Álcool, Diesel e Gasolina.'
            imgSrc={imagemUnidadePalhoca}
            mobile={mobile}
            reverse={true}
            vejaComoChegarUrl={'https://www.google.com/maps/dir//Av.+Ivo+Luchi,+207+-+Jardim+Eldorado,+Palhoça+-+SC,+88133-510/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x952735937c0a2785:0x7177c3b853f1bb8c?sa=X&ved=1t:707&ictx=111'}
          />
          <UnidadeDistribuidora
            titulo='DISTRIBUIDORA'
            local='CHAPECÓ'
            endereco='Rua Equador 275 D - Chapecó/SC'
            descricao1='Localizada na Rua Equador 275 D Bairro Lider - Chapecó - SC'
            imgSrc={imagemUnidadeChapeco}
            mobile={mobile}
            reverse={false}
            vejaComoChegarUrl={'https://www.google.com/maps/dir//R.+Equador,+275d+-+Líder,+Chapecó+-+SC,+89805-210/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x94e4b5d651df00c9:0x59d7a44b7a058bea?sa=X&ved=1t:707&ictx=111'}
          />
          <UnidadeDistribuidora
            titulo='DISTRIBUIDORA'
            local='CURITIBA'
            endereco='Rua Carlos Essenfelder, 2759 - Curitiba/PR'
            descricao1='Localizada na Rua Carlos Essenfelder, 2759 - Curitiba - PR e Inaugurada em 2022, a distribuidora conta com mais de 250 m² com diversos itens para motores Álcool, Diesel e Gasolina.'
            imgSrc={imagemUnidadeCuritiba}
            mobile={mobile}
            reverse={true}
            ultima={true}
            vejaComoChegarUrl={'https://www.google.com/maps/dir//R.+Carlos+Essenfelder,+2759+-+Boqueirão,+Curitiba+-+PR,+81730-060/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x94dcfaee858725e7:0x47ab45af1fa9127a?sa=X&ved=1t:707&ictx=111&cshid=1726014740236059'}
          />
        </div>
        <div className='divDasPrincipaisMarcas'>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: mobile ? 'column' : 'row' }}>
            <h1 className='tituloPrincipaisMarcas'>Trabalhamos com as principais marcas do mercado!</h1>
            <div style={{ width: !mobile ? '40%' : 'auto' }}>
              <h3 className='textoPrincipaisMarcas' style={{ color: '#6B6B6B', fontWeight: '700' }}>São mais de 20 empresas de renome</h3>
              <p className='textoPrincipaisMarcas'>Oferecendo produtos de qualidade garantida e uma ampla gama de peças e equipamentos. Nossa parceria com fornecedores líderes do mercado assegura procedência e diversidade em cada item que disponibilizamo</p>
            </div>
          </div>
          <div className='listagemDeMarcasSitePublicoPagPrincipal'>
            {
              principaisMarcasList.map((marca) => {
                return (
                  <div className='produtoDoCatProdutos'>
                    <img src={(marca.imagem)} alt={marca.alt}></img>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='divFundoAzulYoutube'>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '8%', marginRight: '8%' }}>
            <div>
              <h1 className='catalogoDeProdutosTitulo'>Conteúdos & Dicas</h1>
              <h2 className='subtituloYoutube'>Acompanhe nosso canal no Youtube!</h2>
            </div>
            <img src={iconeDoYoutube} alt='iconeDoYoutube'></img>
          </div>
        </div>
        {
          videos ? (
            <div className="listaDeVideos">
              {videos.map((video) => (
                <a key={video.id.videoId} style={{ display: 'contents' }} href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank" rel="noopener noreferrer">
                  <div key={video.id.videoId} className="itemVideoDoYoutube">
                    <img style={{ width: '100%', borderRadius: '8px 8px 0 0', objectFit: 'cover' }} src={video.snippet.thumbnails.high ? video.snippet.thumbnails.high.url : video.snippet.thumbnails.default.url} alt={video.snippet.title} />
                    <h3 className='tituloVideoYoutube'>{video.snippet.title}</h3>
                  </div>
                </a>
              ))}
            </div>
          ) : null
        }
        <div className='botaoVerTodosConteudos'><a href={"https://www.youtube.com/@girospecas/featured"} target="_blank" rel="noopener noreferrer">Ver todos os conteudos</a></div>
        <BannerGptw />
        <div className='divDoBanner4Instagram'>
          <div className='divTituloBannerInstagram'>
            <h1 className='tituloBannerInstagram'>Já conhece o nosso Instagram?</h1>
            <a href={"https://www.instagram.com/girospecas"} target="_blank" rel="noopener noreferrer" className='botaoInstagramGirosPecas'>
              <img alt='iconeDoInstagram' src={iconeInstagram}></img>
              girospecas
            </a>
          </div>
          <UltimosPostsInstagram/>
        </div>
        <div className='bottomFundoAzulSitePublico'>
          <div className='centroBottomFundoAzul'>
            <div style={{ display: 'flex', gap: '5%', margin: !mobile ? '5% 10%' : '', flexDirection: !mobile ? 'row' : 'column' }}>
              <div>
                <h2 className='tituloOpiniaoClientes'>Opinião dos clientes</h2>
                <div className='cardOpiniaoClientes'>
                  <p className='textoCardOpiniaoCliente'>Quando se reúne uma equipe competente, é certo que os frutos valeram a pena ser colhidos mas a Giros Peças superou minhas expectativas.</p>
                  <p className='textoCardOpiniaoCliente'>Parabéns a todos em especial ao Alex Martha e Oldair May que fazem parte desta equipe.</p>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '1em' }}>
                    <img src={iconOpiniaoCliente} alt=''></img>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                      <label className='nomePessoaOpiniao'>Claudemir João de Quadros</label>
                      <label className='descricaoPessoaOpiniao'>Retifica de Motores Continente</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='divMaiorOpiniaoDosClientes' style={{ marginTop: !mobile ? '' : '5%' }}>
                <div className='cardOpiniaoClientes'>
                  <p className='textoCardOpiniaoCliente'>Nós da Retífica Nelinho gostaríamos de agradecer por todos os anos de parceria com a Giros Peças e por estarem sempre prontos a nos atender quando a gente precisa.</p>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '1em' }}>
                    <img src={iconOpiniaoCliente} alt=''></img>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                      <label className='nomePessoaOpiniao'>Julio de Souza Ferraz Junior</label>
                      <label className='descricaoPessoaOpiniao'>Retífica Nelinho</label>
                    </div>
                  </div>
                </div>
                <h2 className='segundoTituloOpiniaoClientes'>Seja nosso cliente!</h2>
              </div>
            </div>
          </div>
        </div>
        <BottomSitePublico></BottomSitePublico>
      </div >
    </div >
  );
}

export default SitePublico;