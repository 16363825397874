
import Header from '../../componentes/Header';
import { useLocation } from 'react-router-dom';
import Blue from '../../imagensPaginasEnviarArquivos/Blue.png'
import iconFile from '../../imagensPaginasEnviarArquivos/iconFile.png'
import icon4 from '../../imagensPaginasEnviarArquivos/icon4unselect.png'
import iconCheck from '../../imagensPaginasEnviarArquivos/iconOk.png'
import iconInfo from '../../imagensPaginasEnviarArquivos/iconInfo.png'
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import InfoBox from '../../componentes/InfoBox';
import Cookies from 'js-cookie';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
function EnviarNotaGarantiaPage() {
  const location = useLocation();
  const itensSelecionados = location && location.state ? location.state.itensSelecionadosComMotivo : [];
  const notaFiscal = location && location.state ? location.state.notaFiscal : [];

  const [mobile, setMobile] = useState(window.innerWidth <= 850);

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [itensSelecionadosComMotivo, setItensSelecionadosComMotivo] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const navigate = useNavigate();
  const urlCompleto = window.location.href;
  const partesUrl = urlCompleto.split('/');
  partesUrl.pop();
  const urlAnterior = partesUrl.join('/');

  const idDaNota = notaFiscal.id;
  const fileInputRef = useRef(null);
  useEffect(() => {
    setItensSelecionadosComMotivo(itensSelecionados.map(item => {
      return { item: item };
    }));
  }, [])
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFiles([...selectedFiles, file]);
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const toggleInfoBox = () => {
    setShowInfoBox(!showInfoBox);
  };
  const valorUnidadeFormatado = (rowData) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.item.valorUnidade);
  };
  const valorTotalFormatado = (rowData) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.item.valorTotal);
  };
  const valorSubTribFormatado = (rowData) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.item.valorSubTrib);
  };
  const ipiFormatado = (rowData) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.item.ipi);
  };
  const baseIcmsFormatado = (rowData) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.item.baseIcms);
  };
  const icmsFormatado = (rowData) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.item.icms);
  };
  const proximaPagina = async () => {
    const token = Cookies.get('token');

    if (!token) {
      console.error('Token não encontrado no localStorage');
      return;
    }

    // 1. Primeiro request para abrir chamado garantia
    const requestData = {
      idDaNota: idDaNota,
      itensSelecionadosGarantia: itensSelecionados.map((item) => ({
        idDaNota: idDaNota,
        idDoItem: item.id,
        codigoDaPeca: item.codigoDaPeca,
        quantidade: item.quantidadeSelecionada,
        produtoAplicado: item.produtoAplicado === 'true' ? true : false,
        veiculoAplicado: item.veiculo,
        anoDoVeiculo: item.anoVeiculo,
        motorDoVeiculo: item.motor,
        cilindroDoVeiculo: item.cilindros,
        valvulaDoVeiculo: item.valvulas,
        descricaoDoProblema: item.descricaoProblema,
        despesasAdicionais: item.despesasAdicionais === 'true' ? true : false,
        motivo: item.motivo,
        tipo: item.tipo,
        sub: item.subDescricao,
        marca: item.marca,
        antigo: item.antigo,
      })),
    };
    console.log(requestData);
    let response1 = null;
    let response2 = null;
    response1 = await fetch(`${process.env.REACT_APP_API_URL}/garantia/abrirchamado`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(requestData),
    });
    const data1 = await response1.json();
    // 2. Segundo request para upload de comprovante garantia
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('arquivo', selectedFiles[i]);
      }

      response2 = await fetch(`${process.env.REACT_APP_API_URL}/garantia/upload-comprovante/${data1.garantia.id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response2.ok) {
        console.error('Erro na requisição de upload de comprovante:', response2.statusText);
        return;
      }
    }
    if (response1 != null && response2 != null) {
      if (response1.ok && response2.ok) {
        navigate('protocoloCriado', { state: { data1 } });
      } else {
        alert("Ocorreu um erro")
      }
    } else {
      alert("Ocorreu um erro")
    }
    if (response1 != null && response2 != null) {
      if (response1.ok && response2.ok) {
        if (itensSelecionadosComMotivo.some(item => item.item.despesasAdicionais === "true" && item.item.produtoAplicado === "true")) {
          var idDaGarantia = data1.garantia.id;
          navigate('enviarComprovante', { state: { itensSelecionadosComMotivo, notaFiscal, idDaGarantia, data1 } });
        } else {
          navigate('protocoloCriado', { state: { data1 } });
        }

      } else {
        alert("Ocorreu um erro")
      }
    } else {
      alert("Ocorreu um erro")
    }
  }
  return (
    <div className="contenteudo">
      <Header logado={true} />
      <div className='devolucaoDoc' style={{ display: "flex", justifyContent: "center" }}>
        {mobile ? (
          <div style={{ display: 'flex', gap: '1em', flexDirection: 'column', marginTop: '1em', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginLeft: '5%' }}>
              <div className='indicacaoDeEtapaAtualNumeroMobile'>3</div>
              <label className='labelDoIndicacaoDeEtapaAtualNumeroMobile'>Documentação</label>
            </div>
            <div className='barraMaiorIndicacaoMobile'>
              <div style={{ width: '80%' }} className='barraMenorIndicacaoMobile' />
            </div>
          </div>
        ) : null}
        {showInfoBox && (
          <InfoBox toggleInfoBox={toggleInfoBox} garantia={true} />
        )}
        <div className='whiteBoxDoc'>
          {!mobile ? (
            <h1 className='h1Title'>
              Novo Protocolo de Garantia
            </h1>
          ) : null}
          {!mobile ? (
            <div>
              <div className='progressBarDoc'>
                <span className='progressFillDoc'></span>
              </div>
              <div className='progressStageDoc'>
                <div className='stagesDoc'>
                  <img src={iconCheck} alt='' />
                  <h2>Seleção de Itens</h2>
                </div>

                <div className='stagesDoc'>
                  <img src={iconCheck} alt='' />
                  <h2>Informações gerais</h2>
                </div>

                <div className='stagesDoc'>
                  <h2 style={{ color: 'rgb(255, 255, 255)', zIndex: '2', bottom: '0.9px', left: '25.3px', position: 'relative' }}>3</h2>
                  <img src={Blue} alt='' />
                  <h2 style={{ fontWeight: 700, fontSize: 14, color: "#041E42" }}>Documentação</h2>
                </div>

                <div className='stagesDoc'>
                  <img src={icon4} alt='' />
                  <h2>Validação</h2>
                </div>
              </div>
            </div>
          ) : null}
          <hr className="verticalLineBoxDoc" />
          <div className='BottomBox'>
            <h1 className='h1Middle'>
              Anexar Nota de Garantia
            </h1>
            <div className='div2'>
              <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris faucibus sem eu euismod pretium. Aliquam sed vehicula velit. Pellentesque et ex vitae dui consectetur hendrerit.</h2>
              <div className='anexarArquivo' onClick={handleClick}>
                <input type='file' onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
                <img src={iconFile} alt='' />
                <h1>Anexar Arquivo</h1>
              </div>
              {mobile ? (
                <div>
                  <div className='divInfo' onClick={toggleInfoBox}>
                    <img src={iconInfo} className='imgInfo' alt='Icone de Informação' />
                    <h1 className='h1Info'>Preciso de ajuda</h1>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='div3' style={{ width: '80%', marginTop: '3%', marginBottom: '3%' }}>
            <h3>Espelho da nota</h3>
            <DataTable value={itensSelecionadosComMotivo} className='dataTableEspelhoDaNota'>
              <Column field="item.codigoDaPeca" header="Código da Peça" style={{ textAlign: 'left' }}></Column>
              <Column field="item.quantidadeSelecionada" header="Qtd" style={{ textAlign: 'left' }}></Column>
              <Column field="item.cst" header="CST" style={{ textAlign: 'left' }}></Column>
              <Column field="item.cfop" header="CFOP" style={{ textAlign: 'left' }}></Column>
              <Column field="item.valorSubTrib" header="Valor Sub. Trib." body={valorSubTribFormatado} style={{ textAlign: 'left' }}></Column>
              <Column field="item.ipi" header="IPI" body={ipiFormatado} style={{ textAlign: 'left' }}></Column>
              <Column field="item.aliqIpi" header="Aliq. IPI" style={{ textAlign: 'left' }}></Column>
              <Column field="item.baseIcms" header="Base ICMS" body={baseIcmsFormatado} style={{ textAlign: 'left' }}></Column>
              <Column field="item.icms" header="ICMS" body={icmsFormatado} style={{ textAlign: 'left' }}></Column>
              <Column field="item.aliqIcms" header="Aliq. ICMS" style={{ textAlign: 'left' }}></Column>
              <Column field="item.fcpSt" header="FCP ST" style={{ textAlign: 'left' }}></Column>
              <Column field="item.valorUnidade" header="Valor Uni." body={valorUnidadeFormatado} style={{ textAlign: 'left' }}></Column>
              <Column field="item.valorTotal" header="Total" body={valorTotalFormatado} style={{ textAlign: 'left' }}></Column>
            </DataTable>
          </div>
          {!mobile ? (<div className='BottomBox'>
            <div className='divBackFoward' style={{ display: 'flex', paddingBottom: '2lh', marginTop: '2lh', justifyContent: 'space-between' }}>
              <Link to={urlAnterior} style={{ width: 'fit-content', height: 'fit-content' }}>
                <div className='buttonBack' style={{ cursor: 'pointer' }}>
                  <h1 style={{ fontSize: '15px' }}>❮</h1>
                  <h1 style={{ fontSize: '15px' }}>Voltar</h1>
                </div>
              </Link>
              <div className='divInfo' onClick={toggleInfoBox}>
                <img src={iconInfo} className='imgInfo' alt='Icone de Informação' />
                <h1 className='h1Info'>Preciso de ajuda</h1>
              </div>
              <div className='buttonFoward' onClick={proximaPagina} style={{ cursor: 'pointer' }}>
                <h1 style={{ color: '#fff', fontSize: '15px' }}>Prosseguir</h1>
                <h1 style={{ color: '#fff', fontSize: '15px' }}>❯</h1>
              </div>
            </div>
          </div>) : (
            <div className='botoesInferiores' style={{ position: 'fixed' }}>
              <Link to={urlAnterior} style={{ width: 'fit-content', height: 'fit-content' }}>
                <div className='botaoCancelar'><label>❮</label><label>Voltar</label></div>
              </Link>
              <div onClick={proximaPagina} style={{ width: 'fit-content', height: 'fit-content', cursor: 'pointer' }}>
                <div className='botaoProsseguir'>
                  <label style={{ cursor: 'pointer' }}>Prosseguir</label>
                  <label style={{ cursor: 'pointer' }}>❯</label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default EnviarNotaGarantiaPage;