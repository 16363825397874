import React from 'react';
import backgroundImageBannerGptw from './imgs/backgroundImageBannerGptw.jpg'
import certificadoGptw from './imgs/certificadoGptw.png';
import './BannerGptwTrabalheConosco.css';
import bolinhas from './imgs/bolinhasBannerGptw.svg';
const BannerGptwTrabalheConosco = () => {
  return (
    <div id="novo_GPTW" className="gptwTrabalheConosco-container">
      <img
        className="gptwTrabalheConosco-background-img"
        src={backgroundImageBannerGptw}
        alt="Background"
      />
      <div className="gptwTrabalheConosco-overlay"></div>
      <img
        src={certificadoGptw}
        alt="GPTW logo"
        className="gptwTrabalheConosco-logo"
      />
      <img
        src={bolinhas}
        alt=""
        className="gptwTrabalheConosco-bolinhas"
      />
      <div className="gptwTrabalheConosco-content">
        <h1>Nós somos GPTW</h1>
        <h2>Great Place to Work</h2>
        <p>
        A Giros foi reconhecida com o prêmio Great Place to Work (GPTW), destacando nosso compromisso com o bem-estar e a satisfação dos colaboradores. Este reconhecimento reafirma nossa dedicação em criar um ambiente de trabalho positivo e motivador.
        </p>
      </div>
    </div>
  );
}
export default BannerGptwTrabalheConosco;