import Header from '../../../componentes/Header';
import React, { useState, useEffect } from 'react';
import './ListagemMarcasAdmin.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import BotaoDeVoltarAdmin from '../../../componentes/BotaoDeVoltarAdmin';
import iconRemover from './imgs/iconRemoverAdmin.svg';
import iconEditar from './imgs/iconEditarAdmin.svg';
import iconEnviarArquivo from './imgs/iconEnviarArquivoAdmin.svg';
import iconFecharPopup from './imgs/iconFecharPopupAdmin.svg';

function ListagemMarcasAdmin() {

  const [marcaSelecionadaParaCadastrar, setMarcaSelecionadaParaCadastrar] = useState({ nome: '', imagem: '' });
  const [marcaSelecionadaParaEditar, setMarcaSelecionadaParaEditar] = useState({ nome: '', imagem: '' });
  const [idMarcaDeletar, setIdMarcaDeletar] = useState(null);

  const [marcas, setMarcas] = useState([]);
  const [mobile, setMobile] = useState(window.innerWidth <= 850);
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });

  const [mostrarPopupCadastrar, setMostrarPopupCadastrar] = useState(false);
  const [mostrarPopupEditar, setMostrarPopupEditar] = useState(false);
  const [mostrarPopupDeletarMarca, setMostrarPopupDeletarMarca] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setMobile(window.innerWidth <= 850);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });

          if (!data) navigate('/login');
        } catch {
          setStatusUsuario({ logado: false, admin: false });
          navigate('/login');
        }
      };
      fetchData();
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    listarMarcas();
  }, []);

  // Listar marcas
  async function listarMarcas() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/marca/listar`, { method: 'GET' });
      const data = await response.json();
      setMarcas(data);
    } catch (error) {
      alert(error);
    }
  }

  // Função para transformar byte em imagem
  function transformarByteEmImagem(itemData) {
    const byteCharacters = atob(itemData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    return url;
  }

  // Atualizar o estado da imagem
  async function handleEditarImagemMarcaChange(e) {
    let imagem = e.target.files[0];
    setMarcaSelecionadaParaEditar({ ...marcaSelecionadaParaEditar, imagem });
  }

  async function handleCadastrarImagemMarcaChange(e) {
    let imagem = e.target.files[0];
    setMarcaSelecionadaParaCadastrar({ ...marcaSelecionadaParaCadastrar, imagem });
  }

  // Função para salvar alterações na marca
  async function salvarMarca() {
    const formData = new FormData();
    formData.append('nome', marcaSelecionadaParaEditar.nome);
    formData.append('imagem', marcaSelecionadaParaEditar.imagem);

    try {
      const token = Cookies.get('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/marca/atualizar/${marcaSelecionadaParaEditar.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        setMostrarPopupEditar(false);
        listarMarcas();
        setMarcaSelecionadaParaEditar({ nome: "", imagem: "" });
      } else {
        console.log('Erro ao atualizar a marca.');
      }
    } catch (error) {
      alert('Erro ao se comunicar com o servidor.');
    }
  }

  // Função para cadastrar a nova marca
  async function cadastrarMarca() {
    try {
      const formData = new FormData();
      formData.append('nome', marcaSelecionadaParaCadastrar.nome);
      formData.append('imagem', marcaSelecionadaParaCadastrar.imagem);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/marca/criar`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}` },
        body: formData,
      });

      if (response.ok) {
        listarMarcas();
        setMostrarPopupCadastrar(false);
        setMarcaSelecionadaParaCadastrar({ nome: "", imagem: "" });
      } else {
        console.log("Erro ao cadastrar marca");
      }
    } catch (error) {
      console.log('Erro ao cadastrar marca: ' + error);
    }
  }

  // Função para deletar a marca
  async function deletarMarca() {
    try {
      if (idMarcaDeletar) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/marca/deletar/${idMarcaDeletar}`, {
          method: 'DELETE',
          headers: { 'Authorization': `Bearer ${Cookies.get('token')}` },
        });

        if (response.ok) {
          listarMarcas();
          setMostrarPopupDeletarMarca(false);
          setIdMarcaDeletar(null);
        } else {
          console.log("Erro ao deletar marca");
        }
      } else {
        console.log("Nenhuma marca selecionada para deletar")
      }
    } catch (error) {
      console.log('Erro ao deletar marca: ' + error);
    }
  }
  return (
    <div className="homeAdmin-wrapper">
      <Header logado={statusUsuario.logado} admin={statusUsuario.admin} />

      {mostrarPopupDeletarMarca && (
        <div className='marcasAdmin-popupCadastrarNovaMarca' style={{ maxWidth: '28em' }}>
          <h2>Excluir Marca</h2>
          <p style={{ marginBottom: '4em' }}>Você tem certeza que deseja excluir essa Marca? Essa ação não pode ser desfeita.</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button style={{ padding: '1em 2em' }} className='admin-botaoAzulComHover' onClick={() => { setIdMarcaDeletar(null); setMostrarPopupDeletarMarca(false) }}>Não, cancelar</button>
            <button style={{ padding: '1em 2em' }} className='admin-botaoBrancoComHover' onClick={deletarMarca}>Sim, excluir</button>
          </div>
        </div>
      )}

      {mostrarPopupCadastrar && (
        <div className="marcasAdmin-popupCadastrarNovaMarca">
          <h2>Cadastrar marca <img onClick={() => { setMostrarPopupCadastrar(false); setMarcaSelecionadaParaCadastrar({ nome: '', imagem: '' }) }} alt='Fechar popup' src={iconFecharPopup}></img></h2>
          <div className="marcasAdmin-inputsDiv">
            <label>Nome da Marca</label>
            <input
              type="text"
              value={marcaSelecionadaParaCadastrar.nome}
              onChange={(e) =>
                setMarcaSelecionadaParaCadastrar({ ...marcaSelecionadaParaCadastrar, nome: e.target.value })
              }
            />
            <label>Logotipo da marca</label>

            {!marcaSelecionadaParaCadastrar.imagem && (
              <div className="marcasAdmin-upload-area">
                <label htmlFor="logotipoUpload" className="marcasAdmin-upload-label">
                  <img src={iconEnviarArquivo} alt=''></img>
                  <span>Escolher arquivo</span>
                  <input id="logotipoUpload" type="file" onChange={handleCadastrarImagemMarcaChange} className="input-file" />
                </label>
              </div>
            )}

            {marcaSelecionadaParaCadastrar.imagem && (
              <div className="marcasAdmin-upload-area" style={{ border: '1px solid transparent' }}>
                <label htmlFor="logotipoUpload" className="marcasAdmin-upload-label" style={{ padding: '0', overflow: 'hidden' }}>
                  <input id="logotipoUpload" type="file" onChange={handleCadastrarImagemMarcaChange} className="input-file" style={{ display: 'none' }} />
                  <img
                    src={URL.createObjectURL(marcaSelecionadaParaCadastrar.imagem)}
                    alt="Imagem da marca"
                    style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '13em', objectFit: 'contain' }}
                  />
                </label>
              </div>
            )}
            <p className='marcasAdmin-auxilioMedidas'>Medidas 320px por 200px</p>
            <button className='admin-botaoAzulComHover' onClick={cadastrarMarca}>Salvar</button>
          </div>
        </div>
      )}


      {mostrarPopupEditar && (
        <div className="marcasAdmin-popupCadastrarNovaMarca">
          <h2>Editar Marca <img onClick={() => { setMostrarPopupEditar(false); setMarcaSelecionadaParaEditar({ nome: '', imagem: '' }) }} alt='Fechar popup' src={iconFecharPopup}></img></h2>
          <div className="marcasAdmin-inputsDiv">
            <label>Nome da Marca</label>
            <input
              type="text"
              value={marcaSelecionadaParaEditar.nome}
              onChange={(e) =>
                setMarcaSelecionadaParaEditar({ ...marcaSelecionadaParaEditar, nome: e.target.value })
              }
            />
            <label>Logotipo da marca</label>

            {(!marcaSelecionadaParaEditar.imagem) && (
              <div className="marcasAdmin-upload-area">
                <label htmlFor="logotipoUpload" className="marcasAdmin-upload-label">
                  <img src={iconEnviarArquivo} alt=''></img>
                  <span>Escolher arquivo</span>
                  <input id="logotipoUpload" type="file" onChange={handleEditarImagemMarcaChange} className="input-file" />
                </label>
              </div>
            )}

            {marcaSelecionadaParaEditar.imagem && (
              <div className="marcasAdmin-upload-area" style={{ border: '1px solid transparent' }}>
                <label htmlFor="logotipoUpload" className="marcasAdmin-upload-label" style={{ padding: '0', overflow: 'hidden' }}>
                  <input id="logotipoUpload" type="file" onChange={handleEditarImagemMarcaChange} className="input-file" style={{ display: 'none' }} />
                  {marcaSelecionadaParaEditar.imagem && (
                    <img
                      src={
                        typeof marcaSelecionadaParaEditar.imagem === 'string'
                          ? transformarByteEmImagem(marcaSelecionadaParaEditar.imagem)
                          : URL.createObjectURL(marcaSelecionadaParaEditar.imagem)
                      }
                      style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '13em', objectFit: 'contain' }}
                      alt=""
                    />
                  )}
                </label>
              </div>
            )}
            <p className='marcasAdmin-auxilioMedidas'>Medidas 320px por 200px</p>
            <button className='admin-botaoAzulComHover' onClick={salvarMarca}>Salvar</button>
          </div>
        </div>
      )}


      <div className="homeAdmin-content">
        <BotaoDeVoltarAdmin />
        <div className="marcasAdmin-titleDiv">
          <h1 className="homeAdmin-title">Marcas</h1>
          <button onClick={() => setMostrarPopupCadastrar(!mostrarPopupCadastrar)}>Cadastrar nova</button>
        </div>
        <div className="marcasAdmin-grid">
          <div className="marcasAdmin-grid-header">
            <div className="header-item" style={{ paddingLeft: '1em', minWidth: '11.2em' }}>Imagem</div>
            <div className="header-item">Marca</div>
            <div className="header-item"></div>
          </div>
          {marcas.map((marca, index) => (
            <div className="marcasAdmin-grid-row" key={index}>
              <div className="grid-item" style={{ paddingLeft: '1em', minWidth: '10em' }}>
                <img src={transformarByteEmImagem(marca.imagem)} alt={marca.nome} />
              </div>
              <div className="grid-item">{marca.nome}</div>
              <div className="grid-item" style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
                <div className='divBotoesGridItem'>
                  <button
                    onClick={() => {
                      setMarcaSelecionadaParaEditar(marcas[index]);
                      setMostrarPopupEditar(true);
                    }}
                  >
                    <img src={iconEditar} alt="" />Editar
                  </button>
                  <button onClick={() => { setIdMarcaDeletar(marca.id); setMostrarPopupDeletarMarca(true) }}><img src={iconRemover} alt="" /></button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ListagemMarcasAdmin;