import Header from '../../../componentes/Header';
import React, { useState, useEffect } from 'react';
import './ListagemProdutosAdmin.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import BotaoDeVoltarAdmin from '../../../componentes/BotaoDeVoltarAdmin';
import iconRemover from './imgs/iconRemoverAdmin.svg';
import iconEditar from './imgs/iconEditarAdmin.svg';
import iconEnviarArquivo from './imgs/iconEnviarArquivoAdmin.svg';
import iconFecharPopup from './imgs/iconFecharPopupAdmin.svg';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';

function ListagemProdutosAdmin() {

  const [produtoSelecionadoParaCadastrar, setProdutoSelecionadoParaCadastrar] = useState({ nome: '', imagem: '', descricao: '' });
  const [produtoSelecionadoParaEditar, setProdutoSelecionadoParaEditar] = useState({ nome: '', imagem: '', descricao: '' });
  const [idProdutoDeletar, setIdProdutoDeletar] = useState(null);

  const [produtos, setProdutos] = useState([]);
  const [linhas, setLinhas] = useState([]);
  const [mobile, setMobile] = useState(window.innerWidth <= 850);
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });

  const [mostrarPopupCadastrar, setMostrarPopupCadastrar] = useState(false);
  const [mostrarPopupEditar, setMostrarPopupEditar] = useState(false);
  const [mostrarPopupDeletar, setMostrarPopupDeletar] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setMobile(window.innerWidth <= 850);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });

          if (!data) navigate('/login');
        } catch {
          setStatusUsuario({ logado: false, admin: false });
          navigate('/login');
        }
      };
      fetchData();
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    listarProdutos();
  }, []);

  // Listar marcas
  async function listarProdutos() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/produto/listar`, { method: 'GET' });
      const data = await response.json();
      setProdutos(data);
    } catch (error) {
      alert(error);
    }
  }

  // Função para transformar byte em imagem
  function transformarByteEmImagem(itemData) {
    const byteCharacters = atob(itemData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    return url;
  }

  // Atualizar o estado da imagem
  async function handleEditarImagemMarcaChange(e) {
    let imagem = e.target.files[0];
    setProdutoSelecionadoParaEditar({ ...produtoSelecionadoParaEditar, imagem });
  }

  async function handleCadastrarImagemMarcaChange(e) {
    let imagem = e.target.files[0];
    setProdutoSelecionadoParaCadastrar({ ...produtoSelecionadoParaCadastrar, imagem });
  }

  // Função para salvar alterações no produto
  async function salvarProduto() {
    const formData = new FormData();
    formData.append('nome', produtoSelecionadoParaEditar.nome);
    formData.append('nomeLinha', produtoSelecionadoParaEditar.linha ? produtoSelecionadoParaEditar.linha.nome : '');
    formData.append('idMarca', produtoSelecionadoParaEditar.marca ? produtoSelecionadoParaEditar.marca.id : '');
    formData.append('descricao', produtoSelecionadoParaEditar.descricao || '');

    if (produtoSelecionadoParaEditar.imagem instanceof File) {
      formData.append('imagem', produtoSelecionadoParaEditar.imagem);
    }

    try {
      const token = Cookies.get('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/produto/atualizar/${produtoSelecionadoParaEditar.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        setMostrarPopupEditar(false);
        listarProdutos();
        setProdutoSelecionadoParaEditar({ nome: "", imagem: "", nomeLinha: "", idMarca: "", descricao: "" });
      } else {
        const errorText = await response.text();
        console.log(`Erro ao atualizar o produto: ${errorText}`);
      }
    } catch (error) {
      console.error('Erro ao se comunicar com o servidor:', error);
    }
  }

  // Função para cadastrar a nova marca
  async function cadastrarProduto() {
    try {
      const formData = new FormData();
      formData.append('nome', produtoSelecionadoParaCadastrar.nome);
      formData.append('imagem', produtoSelecionadoParaCadastrar.imagem);
      formData.append('nomeLinha', produtoSelecionadoParaCadastrar.linha.nome);
      formData.append('idMarca', produtoSelecionadoParaCadastrar.marca.id);
      formData.append('descricao', produtoSelecionadoParaCadastrar.descricao);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/produto/criar`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}` },
        body: formData,
      });

      if (response.ok) {
        listarProdutos();
        setMostrarPopupCadastrar(false);
        setProdutoSelecionadoParaCadastrar({ nome: "", imagem: "" });
      } else {
        console.log("Erro ao cadastrar marca");
      }
    } catch (error) {
      console.log('Erro ao cadastrar marca: ' + error);
    }
  }

  // Função para deletar a marca
  async function deletarProduto() {
    try {
      if (idProdutoDeletar) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/produto/deletar/${idProdutoDeletar}`, {
          method: 'DELETE',
          headers: { 'Authorization': `Bearer ${Cookies.get('token')}` },
        });

        if (response.ok) {
          listarProdutos();
          setMostrarPopupDeletar(false);
          setIdProdutoDeletar(null);
        } else {
          console.log("Erro ao deletar marca");
        }
      } else {
        console.log("Nenhuma marca selecionada para deletar")
      }
    } catch (error) {
      console.log('Erro ao deletar marca: ' + error);
    }
  }


  // Função para listar marcas
  async function listarMarcas() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/marca/listar`, { method: 'GET' });
      const data = await response.json();
      setNomesMarcas(data);
    } catch (error) {
      alert(error);
    }
  }
  // Função para listar marcas
  async function listarLinhas() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/linhasProdutos/listar`, { method: 'GET' });
      const data = await response.json();
      setLinhas(data);
    } catch (error) {
      alert(error);
    }
  }
  useEffect(() => {
    listarMarcas();
    listarLinhas();
  }, [mostrarPopupCadastrar, mostrarPopupDeletar]);

  const [filteredMarcasCadastro, setFilteredMarcasCadastro] = useState([]);
  const [nomesMarcas, setNomesMarcas] = useState([]);

  function searchMarcasCadastro(event) {
    setTimeout(() => {
      let _filteredMarcas;
      if (!event.query.trim().length) {
        _filteredMarcas = [...nomesMarcas];
      } else {
        _filteredMarcas = nomesMarcas.filter((marca) => {
          if (event.query.nome) {
            return marca.nome.toLowerCase().startsWith(event.query.nome.toLowerCase());
          } else {
            return marca.nome.toLowerCase().startsWith(event.query.toLowerCase());
          }
        });
      }
      setFilteredMarcasCadastro(_filteredMarcas);
    }, 250);
  }

  return (
    <div className="homeAdmin-wrapper">
      <Header logado={statusUsuario.logado} admin={statusUsuario.admin} />

      {mostrarPopupDeletar && (
        <div className='marcasAdmin-popupCadastrarNovaMarca' style={{ maxWidth: '28em' }}>
          <h2>Excluir Produto</h2>
          <p style={{ marginBottom: '4em' }}>Você tem certeza que deseja excluir este Produto? Esta ação não pode ser desfeita.</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button style={{ padding: '1em 2em' }} className='admin-botaoAzulComHover' onClick={() => { setIdProdutoDeletar(null); setMostrarPopupDeletar(false) }}>Não, cancelar</button>
            <button style={{ padding: '1em 2em' }} className='admin-botaoBrancoComHover' onClick={deletarProduto}>Sim, excluir</button>
          </div>
        </div>
      )}

      {mostrarPopupCadastrar && (
        <div className="marcasAdmin-popupCadastrarNovaMarca" style={{ minWidth: '35vw', maxHeight: '70vh', overflowX: 'scroll' }}>
          <h2>Cadastrar produto <img onClick={() => { setMostrarPopupCadastrar(false); setProdutoSelecionadoParaCadastrar({ nome: '', imagem: '' }) }} alt='Fechar popup' src={iconFecharPopup}></img></h2>
          <div className="marcasAdmin-inputsDiv" style={{ alignItems: 'center' }}>
            <div className='produtosAdmin-divMaiorInputs'>
              <div className='produtosAdmin-divDosInputsDeTexto'>
                <label>Nome produto</label>
                <input
                  type="text"
                  value={produtoSelecionadoParaCadastrar.nome}
                  onChange={(e) =>
                    setProdutoSelecionadoParaCadastrar({ ...produtoSelecionadoParaCadastrar, nome: e.target.value })
                  }
                >
                </input>
                <label>Nome marca</label>
                <AutoComplete
                  value={produtoSelecionadoParaCadastrar.marca}
                  suggestions={filteredMarcasCadastro}
                  style={{ height: 'auto' }}
                  className='autoCompleteListagemProdutosAdmin'
                  completeMethod={searchMarcasCadastro}
                  field="nome"
                  onChange={(e) => setProdutoSelecionadoParaCadastrar({ ...produtoSelecionadoParaCadastrar, marca: e.target.value })}
                  forceSelection
                />
                <label>Descrição produto</label>
                <input
                  type="text"
                  value={produtoSelecionadoParaCadastrar.descricao}
                  onChange={(e) =>
                    setProdutoSelecionadoParaCadastrar({ ...produtoSelecionadoParaCadastrar, descricao: e.target.value })
                  }
                />
                <label>Linha do produto</label>
                <Dropdown checkmark={true} style={{ width: '100%', padding: '1em', border: '2px solid #888888', marginBottom: '7em' }}
                  className='dropdownSelecionarLinhaCatProdutos' value={produtoSelecionadoParaCadastrar.linha}
                  onChange={(e) => setProdutoSelecionadoParaCadastrar({ ...produtoSelecionadoParaCadastrar, linha: e.target.value })}
                  options={linhas} optionLabel='nome' placeholder='Selecione a Linha' panelClassName='abasDropdownFiltrosSitePublico'
                />
              </div>
              <div className='produtosAdmin-divDoInputDeImagem'>
                <label>Imagem Produto</label>
                {!produtoSelecionadoParaCadastrar.imagem && (
                  <div className="marcasAdmin-upload-area">
                    <label htmlFor="logotipoUpload" className="marcasAdmin-upload-label" style={{ padding: '4em 7em' }}>
                      <img src={iconEnviarArquivo} alt=''></img>
                      <span>Escolher arquivo</span>
                      <input id="logotipoUpload" type="file" onChange={handleCadastrarImagemMarcaChange} className="input-file" />
                    </label>
                  </div>
                )}

                {produtoSelecionadoParaCadastrar.imagem && (
                  <div className="marcasAdmin-upload-area" style={{ border: '1px solid transparent' }}>
                    <label htmlFor="logotipoUpload" className="marcasAdmin-upload-label" style={{ padding: '0', overflow: 'hidden' }}>
                      <input id="logotipoUpload" type="file" onChange={handleCadastrarImagemMarcaChange} className="input-file" style={{ display: 'none' }} />
                      <img
                        src={URL.createObjectURL(produtoSelecionadoParaCadastrar.imagem)}
                        alt="Imagem da marca"
                        style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '13em', objectFit: 'contain' }}
                      />
                    </label>
                  </div>
                )}
                <p className='marcasAdmin-auxilioMedidas'>Medidas 320px por 200px</p>
              </div>
            </div>
            <button className='admin-botaoAzulComHover' style={{ padding: '1em 8em' }} onClick={cadastrarProduto}>Salvar</button>
          </div>
        </div>
      )}


      {mostrarPopupEditar && (
        <div className="marcasAdmin-popupCadastrarNovaMarca" style={{ minWidth: '35vw', maxHeight: '70vh', overflowX: 'scroll' }}>
          <h2>Editar produto <img onClick={() => { setMostrarPopupEditar(false); setProdutoSelecionadoParaEditar({ nome: '', imagem: '' }) }} alt='Fechar popup' src={iconFecharPopup}></img></h2>
          <div className="marcasAdmin-inputsDiv" style={{ alignItems: 'center' }}>
            <div className='produtosAdmin-divMaiorInputs'>
              <div className='produtosAdmin-divDosInputsDeTexto'>
                <label>Nome produto</label>
                <input
                  type="text"
                  value={produtoSelecionadoParaEditar.nome}
                  onChange={(e) =>
                    setProdutoSelecionadoParaEditar({ ...produtoSelecionadoParaEditar, nome: e.target.value })
                  }
                >
                </input>
                <label>Nome marca</label>
                <AutoComplete
                  value={produtoSelecionadoParaEditar.marca}
                  suggestions={filteredMarcasCadastro}
                  style={{ height: 'auto' }}
                  className='autoCompleteListagemProdutosAdmin placeholderFalso'
                  completeMethod={searchMarcasCadastro}
                  field="nome"
                  placeholder={produtoSelecionadoParaEditar.nomeMarca}
                  onChange={(e) => setProdutoSelecionadoParaEditar({ ...produtoSelecionadoParaEditar, marca: e.target.value })}
                  forceSelection
                />
                <label>Descrição produto</label>
                <input
                  type="text"
                  value={produtoSelecionadoParaEditar.descricao}
                  onChange={(e) =>
                    setProdutoSelecionadoParaEditar({ ...produtoSelecionadoParaEditar, descricao: e.target.value })
                  }
                />
                <label>Linha do produto</label>
                <Dropdown checkmark={true} style={{ width: '100%', padding: '1em', border: '2px solid #888888', marginBottom: '7em' }}
                  className='dropdownSelecionarLinhaCatProdutos' value={produtoSelecionadoParaEditar.linha}
                  onChange={(e) => setProdutoSelecionadoParaEditar({ ...produtoSelecionadoParaEditar, linha: e.target.value })}
                  options={linhas} optionLabel='nome' placeholder={produtoSelecionadoParaEditar.nomeLinha ? produtoSelecionadoParaEditar.nomeLinha : 'Selecione a Linha'} panelClassName='abasDropdownFiltrosSitePublico'
                />
              </div>
              <div className='produtosAdmin-divDoInputDeImagem'>
                <label>Imagem Produto</label>

                {(!produtoSelecionadoParaEditar.imagem) && (
                  <div className="marcasAdmin-upload-area">
                    <label htmlFor="logotipoUpload" className="marcasAdmin-upload-label" style={{ padding: '4em 7em' }}>
                      <img src={iconEnviarArquivo} alt=''></img>
                      <span>Escolher arquivo</span>
                      <input id="logotipoUpload" type="file" onChange={handleEditarImagemMarcaChange} className="input-file" />
                    </label>
                  </div>
                )}

                {produtoSelecionadoParaEditar.imagem && (
                  <div className="marcasAdmin-upload-area" style={{ border: '1px solid transparent' }}>
                    <label htmlFor="logotipoUpload" className="marcasAdmin-upload-label" style={{ padding: '0', overflow: 'hidden' }}>
                      <input id="logotipoUpload" type="file" onChange={handleEditarImagemMarcaChange} className="input-file" style={{ display: 'none' }} />
                      {produtoSelecionadoParaEditar.imagem && (
                        <img
                          src={
                            typeof produtoSelecionadoParaEditar.imagem === 'string'
                              ? transformarByteEmImagem(produtoSelecionadoParaEditar.imagem)
                              : URL.createObjectURL(produtoSelecionadoParaEditar.imagem)
                          }
                          style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '13em', objectFit: 'contain' }}
                          alt=""
                        />
                      )}
                    </label>
                  </div>
                )}

                <p className='marcasAdmin-auxilioMedidas'>Medidas 320px por 200px</p>
              </div>
            </div>
            <button className='admin-botaoAzulComHover' style={{ padding: '1em 8em' }} onClick={salvarProduto}>Salvar</button>
          </div>
        </div>
      )}


      <div className="homeAdmin-content">
        <BotaoDeVoltarAdmin />
        <div className="marcasAdmin-titleDiv">
          <h1 className="homeAdmin-title">Produtos</h1>
          <button onClick={() => setMostrarPopupCadastrar(!mostrarPopupCadastrar)}>Cadastrar nova</button>
        </div>
        <div className="marcasAdmin-grid">
          <div className="marcasAdmin-grid-header" style={{ gridTemplateColumns: '1fr 2fr 2fr 2fr 3fr' }}>
            <div className="header-item headerColunaDaImagemGrid">Imagem</div>
            <div className="header-item">Nome</div>
            <div className="header-item">Marca</div>
            <div className="header-item">Linha</div>
            <div className="header-item"></div>
          </div>
          {produtos.map((produto, index) => (
            <div className="marcasAdmin-grid-row" key={index} style={{ gridTemplateColumns: '1fr 2fr 2fr 2fr 3fr' }}>
              <div className="grid-item colunaDaImagemGrid">
                <img src={transformarByteEmImagem(produto.imagem)} alt={produto.nome} />
              </div>
              <div className="grid-item">{produto.nome}</div>
              <div className="grid-item">{produto.nomeMarca}</div>
              <div className="grid-item">{produto.nomeLinha}</div>
              <div className="grid-item" style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
                <div className='divBotoesGridItem'>
                  <button
                    onClick={() => {
                      setProdutoSelecionadoParaEditar(produtos[index]);
                      setMostrarPopupEditar(true);
                    }}
                  >
                    <img src={iconEditar} alt="" />Editar
                  </button>
                  <button onClick={() => { setIdProdutoDeletar(produto.id); setMostrarPopupDeletar(true) }}><img src={iconRemover} alt="" /></button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ListagemProdutosAdmin;